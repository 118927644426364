import React from 'react';

const quandoraData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/quandora/small/0.png'),
        large: require('../../assets/images/ourWork/quandora/large/0.png'),
      },
      {
        small: require('../../assets/images/ourWork/quandora/small/1.png'),
        large: require('../../assets/images/ourWork/quandora/large/1.png'),
      },
      {
        small: require('../../assets/images/ourWork/quandora/small/2.png'),
        large: require('../../assets/images/ourWork/quandora/large/2.png'),
      },
      {
        small: require('../../assets/images/ourWork/quandora/small/3.png'),
        large: require('../../assets/images/ourWork/quandora/large/3.png'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/quandora/card/quandora-mobile.jpg'),
    // bannerImage: require('../../assets/images/ourWork/quandora/bann'),
    title: 'Quandora Mobile App | Enterprise Q&A Platform',
    shortTitle: 'Quandora Mobile App | Enterprise Q&A Platform',
    id: 'Quandora_Mobile_App',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/quandora-min.png'),
    shortDescription: `The Quandora Q&A platform was built to promote knowledge sharing and collaboration 
    for technical teams. The platform is ultimately designed to help people within companies share their experience, 
    insights, and expertise.`,
    cardDescription: 'A Q&A platform built to promote knowledge sharing and collaboration for technical teams',
    description:
  <div>
    The
    {' '}
    <a href="https://www.quandora.com/questions-and-answers-software-tour/" target="_blank" rel="noreferrer">
      Quandora Q&A
    </a>
    {' '}
    platform was built to promote knowledge sharing and collaboration for technical teams.
    The platform is ultimately designed to help people within companies share their experience, insights, and expertise.
    <br />
    <br />
    Features:
    <br />
    <ul type="disc">
      <li>Easy onboarding;</li>
      <li>Intuitive user experience;</li>
      <li>Push notifications and subscriptions for topics of interest;</li>
      <li>SSO - integrations with popular Enterprise tools (Slack, GSuite, Zendesk, SalesForce, GitHub);</li>
      <li>Powerful search and discovery capability.</li>
    </ul>
    Quandora partnered with MCRO to develop the Q&A mobile app and to implement
    the best technical solutions to achieve business goals and objectives.
  </div>,
    services: ['Product Strategy', 'Software Development', 'QA & Automation'],
    technologies: ['React Native', 'Redux', 'Redux-Thunk', 'Firebase', 'Push Notifications', 'UX&UI', 'WebSockets',
      'Socket.IO', 'Cross Platform', 'iOS', 'Android'],
    results: [
      { percentage: '100', text: 'Organizations' },
      { percentage: '100K+', text: 'Users' },
      { percentage: '3x', text: 'Revenue Growth' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Events'],
    mainCategory: ['Mobile'],
    servicesCategory: [],
    servicesTags: [],
    linkId: 'quandora',
    challenges: [{
      challenge:
  <div>
    Develop a mobile app that enables teams and companies to ask questions,
    get answers and share knowledge in an efficient, secure and convenient manner.
    <br />
    <br />
    Ultimately, our challenge was to create an intuitive environment that would allow its users
    to effortlessly share knowledge,
    a platform to promote effective communication between various employees within organisations.
  </div>,
      solution:
  <div>
    We worked on implementing the Q&A app using the React Native framework in which we integrated
    popular Enterprise tools such as Google Apps, Slack and SAML2/OAuth,
    facilitating the access to the core functionalities.
    <br />
    <br />
    We worked in an agile and collaborative way with daily standups and weekly demo meetings
    to help us stay in sync and up-to-date in all areas of the project.
    <br />
    <br />
    We established a continuous collaboration with various stakeholders from our client’s part
    to ensure transparency and to make sure that all parties are on the same page.
    <br />
    <br />
    MCRO is proud to have been able to deliver a scalable and dynamic
    solution that met our client’s needs and expectations.
  </div>
    }]
  },
];

export default quandoraData;
