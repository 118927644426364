import React from 'react';

const herewithData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/herewith/small/1.png'),
        large: require('../../assets/images/ourWork/herewith/large/1.png')
      },
      {
        small: require('../../assets/images/ourWork/herewith/small/2.png'),
        large: require('../../assets/images/ourWork/herewith/large/2.png')
      },
      {
        small: require('../../assets/images/ourWork/herewith/small/3.png'),
        large: require('../../assets/images/ourWork/herewith/large/3.png')
      },
      {
        small: require('../../assets/images/ourWork/herewith/small/4.png'),
        large: require('../../assets/images/ourWork/herewith/large/4.png')
      },
    ],
    cardImage: require('../../assets/images/ourWork/herewith/card/thumbnail-min.png'),
    bannerImage: require('../../assets/images/ourWork/herewith/banner/banner.jpg'),
    title: 'Herewith | Finding senior care with confidence, not confusion',
    shortTitle: 'Herewith | Finding senior care with confidence, not confusion',
    id: 'Herewith',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/herewith.svg'),
    shortDescription:
      'Herewith provides a platform to assist caregivers and care recipients in connecting regarding'
      + ' in-home non-medical care opportunities.',
    cardDescription:
      'Herewith provides a platform to assist caregivers and care recipients in connecting regarding'
      + ' in-home non-medical care opportunities.',
    description: (
      <div>
        {' '}
        <a href="https://herewith.com/" target="_blank" rel="noreferrer">
          Herewith
          {' '}
        </a>
        {' '}
        provides a platform to assist caregivers and care recipients in
        connecting regarding in-home non-medical care opportunities.
        <br />
        {' '}
        <br />
        Herewith had 2 applications designated to caregivers and caretakers
        respectively. They provide the best way of finding a caregiver for an
        elderly person, based on very specific criteria.
      </div>
    ),
    services: [
      'Product Strategy',
      'Software Development',
      'DevOps & Infrastructure'
    ],
    technologies: [
      'NextJS',
      'Typescript',
      'SwiftUI',
      'Kotlin',
      'Retrofit',
      'WebSockets',
      'Firebase',
      'Firebase analytics',
      'Segment',
      'Stripe',
      'Homework solutions',
      'React Admin',
      'Intercom'
    ],
    servicesCategory: [],
    servicesTags: [],
    categories: ['Featured', 'Healthcare', 'Web', 'Mobile'],
    mainCategory: ['Web', 'Mobile'],
    linkId: 'herewith',
    challenges: [
      {
        challenge: (
          <div>
            Mobile and Web applications with a very intuitive interface and a very well thought out UX to make it easy
            to use even for the elderly. On top of that a back office solution for the care agents to help new users
            with the onboarding process.
          </div>
        ),
        solution: (
          <div>
            A seamless integration of 2 mobile applications, built natively using Swift for iOS and Kotlin for Android.
            Besides this, a web application was built to handle all of the functionality present in the mobile apps
            while also a payment processing solution.
            <br />
            <br />
            Homework Solutions was used to automate the payroll and handle taxes for both caregivers and caretakers.
            Stripe has also been used for users that wanted to go through a more lightweight onboarding process.
          </div>
        )
      }
    ]
  }
];

export default herewithData;
