import React from 'react';

const smashcutData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/smashcut/small/0.jpg'),
        large: require('../../assets/images/ourWork/smashcut/large/0.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/smashcut/small/1.jpg'),
        large: require('../../assets/images/ourWork/smashcut/large/1.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/smashcut/small/2.jpg'),
        large: require('../../assets/images/ourWork/smashcut/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/smashcut/small/3.jpg'),
        large: require('../../assets/images/ourWork/smashcut/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/smashcut/small/4.jpg'),
        large: require('../../assets/images/ourWork/smashcut/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/smashcut/small/5.jpg'),
        large: require('../../assets/images/ourWork/smashcut/large/5.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/smashcut/card/smashcut.jpg'),
    bannerImage: require('../../assets/images/ourWork/smashcut/banner/banner.jpg'),
    title: 'SmashCut | Online Film Educational Platform',
    shortTitle: 'SmashCut | Online Film Educational Platform',
    id: 'SMASHCUT',
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/smashcut-min.png'),
    shortDescription: 'An online learning platform build for real-time, media-based education and creative '
      + 'collaboration between industry professionals and aspiring filmmakers.',
    cardDescription: 'An online film school providing media-based education and one-on-one mentoring',
    description:
  <div>
    <a href="https://www.smashcut.com" target="_blank" rel="noreferrer">Smashcut</a>
    {' '}
    is a next-generation learning platform built for real-time, media-based education. Its mission is to develop and
    deliver customized, branded, media-based online programs to schools & universities.
    <br />
    <br />
    The platform features a high degree of collaborative instruction, and real-time student project review
    via live 1:1 video sessions with instructors.
    <br />
    <br />
    Smashcut partnered with MCRO to reimagine the online learning experience for aspiring filmmakers and
    media creators. Together we worked on building a future-proof,
    user-friendly learning solution for educators and students alike.
    <br />
    <br />
    Our mission was to build an online-only film school that would offer an affordable,
    flexible alternative to the existing model.
    <br />
    <br />
    Smashcut students would be able to watch originally produced video lessons, upload filmmaking assignments,
    receive feedback on their projects, and connect live with industry mentors for 1:1 Video Mentor Sessions.
  </div>,
    services: ['Product Strategy', 'UI/UX Design', 'Software Development', 'QA & Automation'],
    technologies: ['ReactJS', 'ES@Next (6+)', 'Redux', 'Redux-Saga', 'Router', 'Styled Components', 'Canvas', 'SVG',
      'NodeJS', 'GraphQL', 'UI/UX', 'Product Development'],
    results: [
      { percentage: '10+', text: 'Universities' },
      { percentage: '5K+', text: 'Onboarded Students' },
      { percentage: '10x', text: 'Revenue Growth' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Education'],
    mainCategory: ['Web'],
    servicesCategory: ['UX/UI Design'],
    servicesTags: ['UX/UI Design'],
    linkId: 'smashcut',
    challenges: [{
      challenge:
  <div>
    Our challenge was to research and design an e-learning experience specifically tailored for film and media,
    taking into account both the needs of students and teachers.
    <br />
    <br />
    We had to deliver an intuitive and engaging user experience that would enhance learning
    and provide an enjoyable educational experience.
    <br />
    <br />
    Moreover, we had to get on the same page with various stakeholders on the company’s product vision and strategy,
    making sure the design matches the vision of the company.
  </div>,
      solution:
  <div>
    We approached the design process in a strategic manner. In order to make sure our decisions were data-driven
    and informed,
    we started out with a UX audit; we researched the market, analyzed the competition,
    and made our way towards understanding the needs of the users.
    <br />
    <br />
    Using the data obtained during the research process, we started sketching solutions,
    from traditional options to creative and even crazy ideas.
    Our goal was to innovate and reimagine the online learning experience in a fresh, future-proof manner.
    <br />
    <br />
    From wireframes to interactive prototypes and high-fidelity mockups, we delivered a product that enhanced the
    overall user experience of the platform & exceeded the expectations of our client.
    <br />
    <br />
    Moreover, we created a design system to optimize for efficiency and flexibility,
    as well as to provide a shared language across design and engineering.
    <br />
    <br />
    On a more technical note, we used Bitmovin to provide adaptive streaming infrastructure
    for video publishing and integrate it within React.JS.
  </div>
    }]
  },
];

export default smashcutData;
