import React from 'react';

const parkingMatchData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/parking-match/small/1.jpg'),
        large: require('../../assets/images/ourWork/parking-match/large/1.jpg')
      },
      {
        small: require('../../assets/images/ourWork/parking-match/small/2.jpg'),
        large: require('../../assets/images/ourWork/parking-match/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/parking-match/small/3.jpg'),
        large: require('../../assets/images/ourWork/parking-match/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/parking-match/small/4.jpg'),
        large: require('../../assets/images/ourWork/parking-match/large/4.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/parking-match/card/card.jpg'),
    bannerImage: require('../../assets/images/ourWork/parking-match/banner/banner.jpg'),
    title: 'Parking Match',
    shortTitle: 'Parking Match',
    id: 'Parking Match',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/parking-match.png'),
    shortDescription: 'Parking Match is a service designed to make parking in big cities easier.',
    cardDescription: 'A service designed to make parking in big cities easier.',
    description:
  <div>
    Parking Match is a service designed to make parking in big cities easier. We bring drivers together to make
    finding and sharing public parking easier.
    <br />
    {' '}
    <br />
    Members of the app exchange information about public parking spaces. Before a driver leaves his parking space, he
    can inform other Parking Match members about it. In this way, the parking space can be passed on directly within
    the community.
  </div>,
    technologies: ['Flutter', 'Here SDK', 'Bloc', 'RxDart', 'Firebase', 'WebSockets', 'Flutter Mixpanel',
      'Map Launcher', 'Stripe', 'SmartLook', 'Amazon Lambda', 'Amazon API Gateway', 'Amazon DynamoDB',
      'Amazon S3', 'Amazon SNS', 'Firebase', 'HERE SDK'],
    services: ['Product Strategy', 'UI/UX Design', 'Software Development', 'Business Analysis', 'QA & Automation',
      'DevOps & Infrastructure', 'Product Management'],
    servicesCategory: ['App Development'],
    servicesTags: ['App Development'],
    categories: ['Featured', 'Ecommerce', 'Real Estate'],
    mainCategory: ['Mobile'],
    linkId: 'parking-match',
    challenges: [{
      challenge:
  <div>
    A system to have a full map integration with navigation in a cross platform native environment using Flutter.
    Integrate a mapping system that can offer turn-by-turn navigation.
    <br />
    {' '}
    <br />
    An algorithm to handle the matches for parking places in real-time
  </div>,
      solution:
  <div>
    We created a solid cross platform application that runs on both iOS and Android with a high level of performance
    in Flutter. We have extensively used Bloc for state management and RxDart to offer a smoother and straight line
    reactive business logic.
    <br />
    {' '}
    <br />
    We used HereSDK on both front-end and backend to handle the streamline navigation and gather the most useful
    information regarding user current location via native GPS system in a very accurate manner.
    <br />
    {' '}
    <br />
    On backend we leveraged on a low cost infrastructure built upon Lambda Functions for all the API calls and web
    sockets connectivity via API Gateway. The matching algorithm beside the pretty complex logic, has a solid
    infrastructure support to run at the best performance level.
  </div>,
    }]
  },
];

export default parkingMatchData;
