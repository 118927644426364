import React from 'react';

const falconGpsData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/falcon-gps/small/2.png'),
        large: require('../../assets/images/ourWork/falcon-gps/large/2.png'),
      },
      {
        small: require('../../assets/images/ourWork/falcon-gps/small/1.png'),
        large: require('../../assets/images/ourWork/falcon-gps/large/1.png'),
      },
      {
        small: require('../../assets/images/ourWork/falcon-gps/small/3.png'),
        large: require('../../assets/images/ourWork/falcon-gps/large/3.png'),
      },
      {
        small: require('../../assets/images/ourWork/falcon-gps/small/0.png'),
        large: require('../../assets/images/ourWork/falcon-gps/large/0.png'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/falcon-gps/card/geozone-falcon.jpg'),
    bannerImage: require('../../assets/images/ourWork/falcon-gps/banner/banner.jpg'),
    title: 'Geozone Falcon GPS | IOT Project for Tracking Satellites',
    shortTitle: 'Geozone Falcon GPS | IOT Project for Tracking Satellites',
    id: 'Geozone_Falcon',
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/geozone-min.png'),
    shortDescription: 'Geozone’s Falcon GPS is a tool designed to track satellites movement and compute '
      + 'corresponding position and noise based on received data.',
    cardDescription: 'A tool designed to track satellite movement and compute corresponding position and noise',
    description:
  <div>
    Geozone’s Falcon GPS is a tool designed to track satellites movement and compute corresponding position and noise
    based on received data. The whole application is deployed and run on a Raspberry Pi as part of an IoT system.
    <br />
    <br />
    MCRO has fully engineered this application from back-end (Python, API, WebSockets and MessageQueue) to front-end
    (React with WS, Canvas and Charts) based on received specifications from Geozone's product team.
  </div>,
    services: ['Product Strategy', 'UI/UX Design', 'Project Management', 'API Development', 'Web Development',
      'Devops & Infrastructure', 'QA & Automation'],
    technologies: ['React', 'Canvas', 'Webpack', 'Sass', 'WebSockets', 'Socket.IO', 'EventBus', 'RabbitMQ', 'Python',
      'API', 'IoT', 'Raspberry Pi', 'UI/UX', 'Product Development'],
    results: [
      { percentage: '200', text: 'Tracked Satelites' },
      { percentage: '1000+', text: 'Users' },
      { percentage: '10+', text: 'Research Facilities' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Open Source'],
    mainCategory: ['Web'],
    servicesCategory: [],
    servicesTags: [],
    linkId: 'falcon-gps',
    challenges: [
      {
        challenge:
  <div>
    Implement a message queue listener account for data flux coming from Raspberry Pi device
    offering information about satellites position and other factors.
  </div>,
        solution:
  <div>
    Implemented an event bus service in NodeJS to account for message queue data coming through RabbitMQ
    and have it displayed in a beautiful React Dashboard in real-time plot and charts.
  </div>
      }
    ]
  },
];

export default falconGpsData;
