import React from "react";

const caretagData = [
  {
    images: [
      {
        small: require("../../assets/images/ourWork/caretag/small/1.jpg"),
        large: require("../../assets/images/ourWork/caretag/large/1.jpg")
      },
      {
        small: require("../../assets/images/ourWork/caretag/small/2.jpg"),
        large: require("../../assets/images/ourWork/caretag/large/2.jpg")
      },
      {
        small: require("../../assets/images/ourWork/caretag/small/3.jpg"),
        large: require("../../assets/images/ourWork/caretag/large/3.jpg")
      },
      {
        small: require("../../assets/images/ourWork/caretag/small/4.jpg"),
        large: require("../../assets/images/ourWork/caretag/large/4.jpg")
      }
    ],
    cardImage: require("../../assets/images/ourWork/caretag/card/caretag-thumbnail.jpg"),
    bannerImage: require("../../assets/images/ourWork/caretag/banner/banner.jpg"),
    title: "Caretag",
    shortTitle: "Caretag",
    id: "caretag",
    hidden: false,
    logo: require("../../assets/images/ourWork/our-work-icons/our-clients/caretag-logo.png"),
    shortDescription:
      "Caretag offers innovative solutions for tracking surgical equipment, enhancing safety and efficiency in healthcare. Their real-time data systems ensure instrument availability, reduce costs, and improve regulatory compliance.",
    cardDescription:
      "Caretag offers innovative solutions for tracking surgical equipment, enhancing safety and efficiency in healthcare. Their real-time data systems ensure instrument availability, reduce costs, and improve regulatory compliance.",
    description: (
      <div>
        Caretag offers advanced solutions for tracking surgical equipment,
        aiming to enhance patient safety and operational efficiency in
        healthcare facilities. Their innovative systems gather real-time data to
        ensure the availability of necessary instruments, thereby reducing costs
        and simplifying resource management in operating rooms (OR) and central
        sterile services departments (CSSD). Caretag's technology integrates
        seamlessly into existing workflows, promoting sustainability, regulatory
        compliance, and a significant return on investment, ultimately improving
        overall productivity and safety in healthcare environments.
      </div>
    ),
    technologies: [
      "React",
      "MobX",
      "Formik",
      "Playwright E2E testing",
      "TypeScript"
    ],
    services: ["Web Development", "QA & Automation"],
    servicesCategory: ["App Development"],
    servicesTags: ["App Development"],
    categories: ["Featured", "Healthcare"],
    mainCategory: ["Web"],
    linkId: "caretag",
    challenges: [
      {
        challenge: (
          <div>
            Healthcare facilities face several critical challenges regarding the
            management of surgical instruments:
            <br /> <br />
            1. Instrument Mismanagement: Efficiently tracking and managing
            surgical instruments is a common issue, leading to frequent
            misplacement, increased operational costs, and potential delays in
            surgical procedures.
            <br /> <br />
            2. Compliance with Regulations: Adhering to the constantly evolving
            laws and regulations regarding the safety and traceability of
            surgical instruments is a persistent challenge, requiring robust
            systems for accurate tracking and documentation.
            <br /> <br />
            3. Operational Inefficiencies: Operational inefficiencies in the OR
            and CSSD can result in significant delays, increased costs, and
            reduced productivity among healthcare staff, ultimately impacting
            patient care and facility performance.
          </div>
        ),
        solution: (
          <div>
            Caretag addresses these challenges with comprehensive solutions
            designed to streamline surgical instrument management:
            <br /> <br />
            1. Real-time Tracking: Utilizing advanced real-time tracking
            technology, Caretag ensures that surgical instruments are always
            available and correctly managed. This minimizes the risk of loss and
            misplacement, leading to more efficient and reliable operations.
            <br /> <br />
            2. Regulatory Compliance: Caretag's systems help healthcare
            facilities maintain compliance with stringent safety and
            traceability regulations. Their precise data collection and
            reporting tools ensure that all necessary documentation is accurate
            and up-to-date.
            <br /> <br />
            3. Operational Efficiency: By optimizing the management and tracking
            of surgical instruments, Caretag significantly enhances operational
            efficiency. This leads to better resource utilization, reduced
            costs, and increased productivity among healthcare staff, ultimately
            improving patient outcomes and facility performance.
          </div>
        )
      }
    ]
  }
];

export default caretagData;
