import React from 'react';

const sigoraSolarData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/sigora-solar/small/0.jpg'),
        large: require('../../assets/images/ourWork/sigora-solar/large/0.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/sigora-solar/small/1.jpg'),
        large: require('../../assets/images/ourWork/sigora-solar/large/1.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/sigora-solar/small/2.jpg'),
        large: require('../../assets/images/ourWork/sigora-solar/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/sigora-solar/small/3.jpg'),
        large: require('../../assets/images/ourWork/sigora-solar/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/sigora-solar/small/4.jpg'),
        large: require('../../assets/images/ourWork/sigora-solar/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/sigora-solar/small/5.jpg'),
        large: require('../../assets/images/ourWork/sigora-solar/large/5.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/sigora-solar/card/sigora.jpg'),
    bannerImage: require('../../assets/images/ourWork/sigora-solar/banner/banner.jpg'),
    title: 'Sigora Solar | Sell Solar Energy Tool',
    shortTitle: 'Sigora Solar',
    id: 'SIGORA_SOLAR',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/sigora-logo.png'),
    shortDescription: 'Web and mobile platform dedicated to helping solar companies improve their process of '
      + 'solar panels installation design and distribution.',
    cardDescription: 'A web and mobile platform built to improve the process of solar panel design and distribution',
    description:
  <div>
    Sigora Solar is a web and mobile platform dedicated to helping solar companies
    improve their process of solar panel design and distribution. The application provides end-to-end
    capabilities that support the entire lifecycle of solar panel installation.
    <br />
    <br />
    {' '}
    With solutions for lead
    management,  solar system design, in-app financing, integrated e-signing, and others, it provides all
    the tools necessary for efficient solar project management.
    {' '}
    <br />
    <br />
    {' '}
    The whole process takes place in a
    carefully designed pipeline that is meant to streamline the workflow for sales people and inform their
    customers about the process and their potential savings.
  </div>,
    technologies: ['React', 'Cordova', 'GraphQL', 'Hashura', 'Auth0', 'DocuSign', 'Leaflet', 'Webpack',
      'Styled Components', 'Cross Platform', 'AWS', 'EventBus'],
    services: ['Product Strategy', 'Project Management', 'UI/UX Design', 'API Development', 'Software Development',
      'Devops & Infrastructure', 'QA & Automation'],
    results: [
      { percentage: '$32M', text: 'Revenue Growth' },
      { percentage: '30%', text: 'Cost Reduction' },
      { percentage: '80%', text: 'Sales Velocity Increase' },
      { percentage: '50%', text: 'Conversion Rate Increase' }],
    testimonial: {
      imageSrc: require('../../assets/images/ourWork/sigora-solar/author.jpg'),
      quote: 'MCRO’s ability to find cutting-edge solutions to complex challenges is very impressive. '
        + 'They are passionate about their work and dedicated to the project’s success; they always go the extra '
        + 'mile to ensure everything runs smoothly and on schedule. '
        + 'MCRO goes above and beyond when it comes to bringing our ideas to life and helping drive our business '
        + 'forward.',
      author: 'Joni Lane',
      position: 'Director of Product Development at Sigora' },
    categories: ['Real Estate', 'Featured'],
    mainCategory: ['Mobile', 'Web'],
    servicesCategory: [],
    servicesTags: [],
    linkId: 'sigora-solar',
    challenges: [
      {
        challenge:
  <div>
    Going solar requires going through a complex and very technical process that intimidates
    most customers. Solar panel distributors have to bridge the knowledge gap and help the client go from
    concept to installation in a clear and comprehensive manner.
    <br />
    <br />
    Our challenge was to create a new and
    improved platform that took into account both the needs of the end user and the customer.
    We had to develop a User Experience strategy that would help Sales Teams handle sales on the spot,
    while making sure that potential customers are fully informed in regards to the process and the ROI
    expectations. Moreover, we needed to create a smooth multi-device user experience that took into
    account multiple users and access levels. The application needed to be available on the Web for
    Admins and higher management/executives, and on Mobile for sales representatives and authorized
    dealers.
  </div>,
        solution:
  <div>
    We focused on improving the overall User Experience of the platform by rethinking user flows and
    aligning them with the needs of the business.
    <br />
    <br />
    We used GoogleMaps API and Leaflet to
    create an accurate and precise design tool that allows sales representatives to quickly draw solar
    panel systems.
    <br />
    <br />
    We created integrations with several financiers (Sunlight Financial, LoanPal etc.)
    to enable in-app loan approval for customers.
    <br />
    <br />
    We leveraged the DocuSign integration to allow for a
    fast and secure electronic signing process.
    <br />
    <br />
    Native app development allowed us to take advantage
    of built-in features to create certain components. For example, we created our own custom plugin for
    taking screenshots and uploading them directly to an S3 Bucket.
    This required us to wrap the application using Cordova. The challenge here
    was to maintain a development friendly environment while implementing an automated build system
    for a React application using the latest Webpack integrated with Cordova.
    <br />
    <br />
    We used Fastlane to
    automate builds and to send applications directly to TestFlight and Appcenter without much human
    integration.
    <br />
    <br />
    We used CodePipeline to automate front-end build deploys to S3/CloudFront, custom APIs,
    GraphQL to Hashura Serverless and other services.
    <br />
    <br />
    We created an end-to-end process for automated UI
    testing using Cypress to facilitate the release process and boost the quality of the delivered product.
    <br />
    <br />
    We integrated Sentry to track down eventual issues in production. Most users don\'t report problems they
    encounter, so this powerful tool helps us identify and track down any potential defects.
  </div>
      }
    ]
  },
];

export default sigoraSolarData;
