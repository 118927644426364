import React from 'react';

const docviserData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/docviser/small/1.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/1.jpg')
      },
      {
        small: require('../../assets/images/ourWork/docviser/small/2.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/docviser/small/3.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/docviser/small/4.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/docviser/small/5.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/5.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/docviser/small/6.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/6.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/docviser/small/7.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/7.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/docviser/small/8.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/8.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/docviser/small/9.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/9.jpg'),
      }, {
        small: require('../../assets/images/ourWork/docviser/small/10.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/10.jpg'),
      }, {
        small: require('../../assets/images/ourWork/docviser/small/11.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/11.jpg'),
      }, {
        small: require('../../assets/images/ourWork/docviser/small/12.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/12.jpg'),
      }, {
        small: require('../../assets/images/ourWork/docviser/small/13.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/13.jpg'),
      }, {
        small: require('../../assets/images/ourWork/docviser/small/14.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/14.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/docviser/small/15.jpg'),
        large: require('../../assets/images/ourWork/docviser/large/15.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/docviser/card/docviser-thumbnail.jpg'),
    bannerImage: require('../../assets/images/ourWork/docviser/banner/banner.jpg'),
    title: 'Docviser',
    shortTitle: 'Docviser',
    id: 'Docviser',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/docviser.png'),
    shortDescription: 'An oncology smart system prescription and patients management tool ',
    cardDescription: 'An oncology smart system prescription and patients management tool ',
    description:
  <div>
    Docviser is an oncology smart system prescription and patients management tool that enables doctors to collaborate
    very efficiently without a necessarily physical presence using virtual medical committees (tumor boards) and a
    collaborative treatment protocol database.
    <br />
    {' '}
    <br />
    The entire in-clinic prescription flow is handled between doctors > pharmacists > nurses that enables them to
    set the right status for every treatment with real-time visibility.
  </div>,
    technologies: ['NextJS', 'Vercel', 'Hubspot', 'React', 'MobX', 'StyledComponents', 'material-ui', 'AgoraSDK',
      'Video-Audio Streaming', 'WebSockets', 'Web Push Notifications', 'S3 + Cloudfront AWS',
      'CodePipeline', 'ReactNative', 'MobX', 'Push Notifications', 'WebSockets', 'Docker',
      'CI - GithubActions', 'Fastlane', 'NestJS', 'DynamoDB', 'Dynamoose', 'Redis', 'Firebase integration',
      'ECS +  ALB', 'SNS', 'CloudFront', 'Serverless (AWS Lambda, CloudFormation, API Gateway)',
      'CodePipeline', '100% Unit Tests coverage for all services', 'Cypress'],
    services: ['Product Strategy', 'UI/UX Design', 'Software Development', 'Business Analysis', 'QA & Automation',
      'DevOps & Infrastructure', 'Product Management'],
    results: [
      { percentage: '40+', text: 'Hospitals' },
      { percentage: '4000+', text: 'Patients' },
      { percentage: '20k+', text: 'Treatments' },
      // { percentage: '450+', text: 'Treatment Protocols' },
      { percentage: '100+', text: 'Virtual Medical Committees / Tumor Boards' }],
    servicesCategory: ['App Development'],
    servicesTags: ['App Development'],
    categories: ['Featured', 'Healthcare'],
    mainCategory: ['Mobile', 'Web'],
    linkId: 'docviser',
    challenges: [{
      challenge:
  <div>
    Create a collaborative tool for doctors to work on a global treatment protocol database.
    <br />
    {' '}
    <br />
    Create a prescription system that enables doctors to prescribe efficient treatments with exact dosages
    calculations, choosing from a shared / global database of protocols.
    <br />
    {' '}
    <br />
    Enable doctors, pharmacists and nurses to collaborate on each prescription and administer drugs efficiently
    without confusions and too many interactions.
    <br />
    {' '}
    <br />
    Enable doctors to collaborate across departments (oncology, surgery, cardiology, anatomo-pathology etc.)
    without physical presence and make the best decisions for their patients.
    <br />
    {' '}
    <br />
    Create a Machine Learning system that will collect relevant information about patients and prescriptions,
    patients' statuses and other genetic data markers to create a treatment forecast for recommended protocols.
    <br />
    {' '}
    <br />
    Create a tool where doctors can create and manage patients' consultations, either online or on-site, and also
    automatically schedule Cycle Due consultations based on the prescribed treatments.
    <br />
    {' '}
    <br />
    Implement a Chat GPT-like AI assistant that can answer questions about the patient’s uploaded files as well as
    check the interaction between the prescribed treatment and the co-medication that the patient is already taking.
  </div>,
      solution:
        <div>
          Implemented a perfectly designed tool for doctors to create a shared database of treatment protocols that
          allows
          them to enter all the relevant data like drugs with active substance, cycles, days spread, dosages, custom
          calculations and localizations, in order to be used while prescribing a treatment. Uses ICD-11 or ICD-10
          database, custom
          cancer localizations, bibliography, and an internal chat for doctors to collaborate and dispute certain
          protocols that
          are not entirely clear.
          <br/>
          {' '}
          <br/>
          Implement a smart and efficient treatment prescription system that uses the treatment protocols database,
          scientific proven calculation methods for doses and other relevant blood tests samples to recommend the best
          suitable protocol.
          <br/>
          {' '}
          <br/>
          We implemented the in-clinic flow that enables doctors to prescribe treatments, validate them and send them to
          pharmacists when they are ready. Pharmacists will then receive them in real-time either by web app
          notifications
          or via mobile app push notifications systems and will be able to change the status and send it further to
          nurses
          for administration. The entire cycle is visible by everyone in real-time and uses a chatroom to efficiently
          collaborate on every prescription.
          <br/>
          {' '}
          <br/>
          We implemented a very performant system for Virtual Medical Committees or Tumor Boards. The system involves
          sharing relevant case study/patient data in a secure and structured environment for doctors in different
          specialisations to
          understand, discuss and make the best decisions for a specific patient. Doctors have the ability to schedule
          a secured and trusted online meeting with other doctors in a Google Meet style conference that supports up to
          30-40 people with audio and video at the same time with adaptive streaming based on their bandwidth. They can
          use the Tumor Board chatroom, invite guests, share important files, and have a full-blown conference all in
          a secured one place, while navigating through the patient’s files and treatment history.
          <br/>
          {' '}
          <br/>
          Patients can be scheduled for consultations, either online or on-site, and the system will automatically send
          the patient a notification with the scheduled time and date. The system will also automatically schedule the next
          Cycle Due on chemotherapy, based on the prescribed treatments. Patients will have access to their files, treatments and
          shared information in real time, and will be able to see the status of their treatments and the next scheduled
          consultation.
          <br/>
          {' '}
          <br/>
          We designed and implemented a Google Calendar-like scheduler, where all users can schedule and manage patient
          consultations, either online or on-site. Furthermore, Pharmacists and Nurse users can see the upcoming
          prescriptions
          in a calendar view, to more efficiently manage the drug preparation and administration process.
          <br/>
          {' '}
          <br/>
          The content of all the patient’s files as well as all the treatment history from Docviser was used in order to
          create a Vector Database for each patient, and using the  GPT-4-turbo, GPT-4o, MedPub and other open-source
          models, we unlocked the possibility of interrogating the AI assistant about the patient’s files, auto-complete
          information in the patient’s medical files & epicrisis and check the interaction between the patient’s
          prescribed medication. Annonymized data was used to train the AI assistant, and the system is GDPR & HIPAA compliant.
        </div>,
    }]
  },
];

export default docviserData;
