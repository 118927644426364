import React from 'react';

const blockbitsIoData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/blockbits-io/small/1.jpg'),
        large: require('../../assets/images/ourWork/blockbits-io/large/1.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/blockbits-io/small/2.jpg'),
        large: require('../../assets/images/ourWork/blockbits-io/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/blockbits-io/small/3.jpg'),
        large: require('../../assets/images/ourWork/blockbits-io/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/blockbits-io/small/4.jpg'),
        large: require('../../assets/images/ourWork/blockbits-io/large/4.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/blockbits-io/card/blockbits.jpg'),
    bannerImage: require('../../assets/images/ourWork/blockbits-io/banner/banner.jpg'),
    title: 'Blockbits | Decentralized Crowdfunding Platform',
    shortTitle: 'Blockbits | Decentralized Crowdfunding Platform',
    id: 'Block_Bits_IO',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-partners/blockbits-min.png'),
    shortDescription: 'A decentralized open-source crowdfunding/investing platform whose mission is to change '
      + 'the rules of the game in the world of investments.',
    cardDescription: 'A decentralized open-source crowdfunding & investing platform',
    description:
  <div>
    A product accelerated from MCRO incubator,
    {' '}
    <a href="https://blockbits.io/" target="_blank" rel="noreferrer">Blockbits.io </a>
    {' '}
    DApp is not just another crowd-funding platform,
    it’s the innovation that sets the standard of what the ICO market urgently needs to become:
    safe investments and legitimate projects.
    For the first time investors can decide if they keep backing a project or take their money back.
    <br />
    <br />
    The platform provides innovative mechanisms such as safe funding vaults and secure milestone based payments
    and is designed to become fully independent, maintained and governed by its token holders.
    <br />
    <br />
    Blockbits is launched through the very code submitted here as an MVP.
  </div>,
    services: ['Product Strategy', 'Software Development', 'Blockchain Development'],
    technologies: ['Blockchain', 'Ethereum', 'Solidity', 'DApps', 'Smart Contracts', 'ICO', 'Javascript', 'NodeJS',
      'React', 'ES@Next'],
    servicesCategory: [],
    servicesTags: [],
    categories: ['Financial', 'Open Source'],
    mainCategory: ['BlockChain', 'Opensource', 'Web'],
    linkId: 'blockbits-io',
    challenges: [
      {
        challenge:
  <div>
    <b>Blind Trust requirements</b>
    <br />
    In the past years, the Ethereum network has seen the launch of numerous
    ICOs. This is good, but unfortunately, most ICOs still ultimately rely on investors’ blind trust.
    This attracts only the more risk inclined investors, while even the moderately risk adverse will
    rightly avoid investing in what looks like get-rich-fast schemes or even outright scams. The
    main issue with a classic ICO is the fact that its token is often not linked to any
    meaningful action in its system. Even when it is, in the overwhelming majority of cases, the
    investor is expected to blindly trust that the project initiators will deliver on their promises.
    <br />
    <br />
    <b>Risk of incorrect or manipulated reviews</b>
    <br />
    The ecosystem addresses the problem of trust through a
    couple of websites that provide ICO reviews and assess the potential risk of investment. This solution
    to the problem of trust is inherently suboptimal: not only are these services highly centralized, but
    they are also paid for by the ICO under review. Bias? and even abuse? is very likely, even with the best of
    intentions.
    <br />
    <br />
    <b>Problems of existing crowd funding platforms</b>
    <br />
    In existing platforms, the owners have ultimate control over which projects get to launch
    through the platform. Thus investors skip an investigation phase that would make sense
    before investing in a project. Their agency is thus relegated to the owners of the platform
    who vet projects for launching.
    Furthermore, some projects even create their “internal currency” token, which, in turn,
    comes with its own set of problems and significant risks. One of them is the requirement to
    mint it and sell it at will, in order to maintain a stable price, this dilutes the initial supply.
    Another one, is the need to rely on the platform’s success in order to find buyers for a recently
    funded campaign.
    Finally, from a security perspective, most platforms are not backed by transparent open
    source code. Instead, they are closed source centralized entities that use human labor for
    day-to-day operations.
    One could argue that the venture capitalists/serial entrepreneurs who own the platforms,
    as well as the human labor they rely on to run the closed systems, are perhaps best qualified
    to vet and run investment projects. However, we maintain, the problem of trust/risk remains
    unaddressed, insofar as such a system will be fraught with issues of competence, integrity,
    and natural human error.
    <br />
    <br />
    <b>Project delivery risk</b>
    <br />
    Existing projects fail, in most cases, to provide a coherent system for linking the project to
    its roadmap and its tokens.
    <br />
    <br />
    <b>Ecosystem price damage through large dumps</b>
    <br />
    A common phenomenon unaddressed by existing platforms, is that, each time a new ICO is
    funded, a large amount of ETH is subsequently dumped on the exchanges. This drives the
    token’s price down and removes value from the network.
    <br />
    <br />
    <b>Token supply and mechanics</b>
    <br />
    In an ideal system, the collected ETH is used for covering development expenses, while owners’
    remaining token supply should be seen as the main source of profits. Having a larger stake in
    the project incentives the owner to deliver.
    Owners should lock their tokens until project delivery in order to limit their ability to
    dilute token market value during development.
    <br />
    <br />
    <b>PRE ICO funding phases</b>
    <br />
    In trying to emulate classic systems, the ICO pre sale has mostly missed the point, i.e., that a
    pre-sale is usually targeted at already existing investors, with contractual rights to buy newly
    created shares. Instead, in the ICO model, pre-sale has become merely a means to raise funds
    for marketing or logistic purposes, by offering a substantial discount to anyone who wishes to
    participate and undertake the initial risk.
    This creates the inability to refund participating investors in the case of a funding failure,
    and also fuels reckless investment and hoarding for the purpose of dumping at a better price
    once the sale period ends.
  </div>,
        solution:
  <div>
    Blockbits provides the optimal solution to the problem of risk and trust in investing.
    Our innovation consists of built-in features and mechanisms that take into account general rules
    of human psychology in relation to risk and investment.
    <br />
    <br />
    Our ultimate goal is to provide a safe investing experience, where:
    <br />
    <br />
    1. Projects advance insofar as they are viable;
    <br />
    2. Risk inherent to human bias or error is minimized.
    <br />
    <br />
    This, in turn, will mean that investing will become accessible and relatively risk-free. To achieve this,
    Blockbits relies on a set of smart contracts onto which we’ve built a complex ecosystem of rules and business logic.
    <br />
    <br />
    This breakthrough technological innovation allows token holders to moderate and validate the development process
    of the platform. The requirement of blind trust into the platform’s development team is virtually eliminated and
    so are many of the problems that would stem from this problem thereafter.
    <br />
    <br />
    Blockbits offers two types of project funding, namely,
    <strong> Direct Funding </strong>
    and
    {' '}
    <strong> Milestone Funding</strong>
    {' '}
    .
    <br />
    <br />
    For both, an innovative Vault mechanism is put in place to protect against hacking. The Vault isolates each
    investor’s funds into a separate contract, with two enforced hardcoded output addresses:
    the platform and the investor wallets.
    <br />
    <br />
    The Milestone funding mechanism mitigates project delivery risk by locking up investor funds until a milestone
    delivery is reached. At this point the investor has the choice to validate and accept the delivered content
    and thus release locked funds to the project owners for the milestone in question.
    In turn, the investor will receive previously locked tokens as compensation.
    <br />
    <br />
    If, instead, the majority of investors do not deem the delivered content as acceptable,
    all investors who voted NO on the proposal,
    can choose to use the CashBack mechanism to retrieve their remaining locked ETH.
    <br />
    <br />
    Essentially, this gives the investors the power to decide if the project delivered on its promises or not.
    Furthermore, voting NO does not require an investor to actually resort to CashBack -
    it may just signal disagreement with the delivery.
    <br />
    <br />
    Investing into Blockbits awards BBX Tokens. These tokens allow the participation in Internal funding phases
    of projects launched through the platform. This does not only provide bonus discounts, but also genuine involvement
    as an investor with the ability to initiate code upgrade proposals. If such upgrades are accepted by the community,
    they will yield revenue and possible return of initial investment through the token buyback program.
    <br />
    <br />
    This unique ensemble of processes makes Blockbits and all the projects launched through it stand out starkly among
    its competitors. They provide an objective and transparent risk analysis based on risk indicators built into the
    software contracts used to run the investment campaign. We call them “Platform testable risk indicators''.
  </div>
      }
    ]
  },
];

export default blockbitsIoData;
