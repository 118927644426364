import React from 'react';

const dstoqExchangeData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/dstoq-exchange/small/0.jpg'),
        large: require('../../assets/images/ourWork/dstoq-exchange/large/0.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/dstoq-exchange/small/1.jpg'),
        large: require('../../assets/images/ourWork/dstoq-exchange/large/1.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/dstoq-exchange/small/2.jpg'),
        large: require('../../assets/images/ourWork/dstoq-exchange/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/dstoq-exchange/small/3.jpg'),
        large: require('../../assets/images/ourWork/dstoq-exchange/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/dstoq-exchange/small/4.jpg'),
        large: require('../../assets/images/ourWork/dstoq-exchange/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/dstoq-exchange/small/5.jpg'),
        large: require('../../assets/images/ourWork/dstoq-exchange/large/5.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/dstoq-exchange/small/6.jpg'),
        large: require('../../assets/images/ourWork/dstoq-exchange/large/6.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/dstoq-exchange/card/dstoq.jpg'),
    bannerImage: require('../../assets/images/ourWork/dstoq-exchange/banner/banner.jpg'),
    title: 'Dstoq Exchange | Stellar Blockchain Decentralization',
    shortTitle: 'Dstoq Exchange | Stellar Blockchain Decentralization',
    id: 'DSTOQ_EXCHANGE',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/logo-DSTOQ.png'),
    shortDescription: 'A decentralized stock exchange platform that promotes economic inclusion by allowing its '
      + 'users to invest in real-world assets using cryptocurrencies. ',
    cardDescription: 'A decentralized exchange platform that allows users to invest through cryptocurrencies',
    description:
  <div>
    <a href="https://dstoq.com/" target="_blank" rel="noreferrer">DSTOQ</a>
    {' '}
    is a decentralized exchange platform that allows users to invest in traditional stocks
    with fiat and cryptocurrencies.
    <br />
    <br />
    The platform enables peer-to-peer trading of tokenized securities such as equities,
    bonds, stocks, oil, and gold using the DSQ token.
    The whole blockchain infrastructure was built on top of the Stellar Network.
    <br />
    <br />
    MCRO was responsible for delivering an initial version of this product.
    We are proud to have delivered a secure,
    scalable product within an Agile environment that had a very tight and unmovable deadline.
  </div>,
    services: ['Mobile Development', 'Product Management', 'Blockchain Development'],
    technologies: ['Blockchain', 'Stellar', 'Stellar JS API', 'React', 'React Native', 'Onfido',
      'KYC React Native Wrapper', 'Python', 'API', 'FastLane', 'Automated Deployment'],
    results: [
      { percentage: '1M+', text: 'Transactions' },
      { percentage: '4K+', text: 'Users' },
      { percentage: '1K+', text: 'Assets' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Financial'],
    mainCategory: ['Mobile', 'BlockChain'],
    servicesCategory: [],
    servicesTags: [],
    linkId: 'dstoq-exchange',
    challenges: [
      {
        challenge:
  <div>
    DSTOQ partnered up with MCRO to build an initial version of the product.
    Our challenge was to build a secure exchange platform that would allow its users
    to invest in global stock markets in a secure and reliable environment.
    <br />
    <br />
    Our mission was to develop a mobile app that would support peer-to-peer trading of
    tokenized real-world assets and that would allow its users to trade securely,
    with full control of their funds and private key.
    <br />
    <br />
    Meeting the tight deadlines and delivering solutions to last-minute challenges was also part of the challenge.
  </div>,
        solution:
  <div>
    From account creation to KYC (Know Your Customer) verification to wallet creation and finally assets trading,
    we had to make sure we were providing the users with a stable platform
    capable to seamlessly go through all these processes.
    <br />
    <br />
    Our stack included the following:
    <br />
    <ul type="disc">
      <li>React Native for app development;</li>
      <li>Onfido for KYC;</li>
      <li>Stellar SDK for assets trading.</li>
    </ul>
    We are proud to have successfully delivered a secure, scalable,
    and robust solution for DSTOQ’s decentralized exchange platform.
  </div>
      }
    ]
  },
];

export default dstoqExchangeData;
