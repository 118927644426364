import React from 'react';

const deliveryTillsterPlatformData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/delivery-tillster-platform/small/0.jpg'),
        large: require('../../assets/images/ourWork/delivery-tillster-platform/large/0.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/delivery-tillster-platform/small/1.jpg'),
        large: require('../../assets/images/ourWork/delivery-tillster-platform/large/1.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/delivery-tillster-platform/small/2.jpg'),
        large: require('../../assets/images/ourWork/delivery-tillster-platform/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/delivery-tillster-platform/small/3.jpg'),
        large: require('../../assets/images/ourWork/delivery-tillster-platform/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/delivery-tillster-platform/small/4.jpg'),
        large: require('../../assets/images/ourWork/delivery-tillster-platform/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/delivery-tillster-platform/small/5.jpg'),
        large: require('../../assets/images/ourWork/delivery-tillster-platform/large/5.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/delivery-tillster-platform/small/6.jpg'),
        large: require('../../assets/images/ourWork/delivery-tillster-platform/large/6.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/delivery-tillster-platform/small/7.jpg'),
        large: require('../../assets/images/ourWork/delivery-tillster-platform/large/7.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/delivery-tillster-platform/card/tillster-delivery.jpg'),
    bannerImage: require('../../assets/images/ourWork/kiosk-tillster-platform/banner/banner.jpg'),
    title: 'Tillster Delivery Platform | Online Food-Ordering',
    shortTitle: 'Tillster Delivery Platform | Online Food-Ordering',
    id: 'Delivery_Tillster_Platform',
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/tillster-min.png'),
    shortDescription: 'An online ordering platform custom-built for popular restaurants and fast food chains to'
      + ' maximize revenue, reduce costs and strenghten customer relationships.',
    cardDescription: 'A custom-built online ordering platform to streamline food delivery orders',
    description:
  <div>
    As a global leader in customer engagement programs and self-service technologies,
    {' '}
    <a href="https://tillster.com" target="_blank" rel="noreferrer">Tillster</a>
    {' '}
    provides online ordering and delivery solutions for giants such as PizzaHut,
    Burger King, KFC, Baskin Robbins, and McAllister.
    <br />
    <br />
    Over the past few years,
    {' '}
    <a href="https://mcro.tech/our-work/kiosk-tillster-platform/" target="_blank" rel="noreferrer">Tillster</a>
    {' '}
    has partnered with MCRO to maintain its
    {' '}
    <a href="https://www.tillster.com/delivery" target="_blank" rel="noreferrer">Delivery Platform</a>
    {' '}
    and to deliver new features as it continues to scale and grow globally.
    <br />
    <br />
    The Delivery Platform offers complete services from end-user applications to kitchen POS integration and payments,
    providing customers all over the world with the best user experience.
  </div>,
    services: ['Software Development', 'Project/Product Management', 'QA & Automation', 'DevOps'],
    technologies: ['Angular', 'ES5/6', 'Grunt', 'Sass', 'Responsive Web', 'Java', 'REST', 'Multi-tenant Platform',
      'Hybrid Cordova', 'iOS', 'Android', 'Cross Platform'],
    results: [
      { percentage: '$100M', text: 'Transactions Increase' },
      { percentage: '65%', text: 'Faster Onboarding' },
      { percentage: '30%', text: 'Traffic Increase' },
      { percentage: '50%', text: 'Lead Velocity Growth' }],
    testimonial: {
      imageSrc: require('../../assets/images/ourWork/kiosk-tillster-platform/dana-cordes-tilster_1-min.png'),
      quote: 'Our partnership with MCRO has been going strong since 2016. What strikes me the most is their '
        + 'dedication and commitment to delivering great work. They are invested in the project and care '
        + 'about the outcome as much as we do. I would definitely recommend working with MCRO as they are '
        + 'extremely competent and thorough about everything.',
      author: 'Dana Cordes',
      position: 'Director of Engineering at Tillster' },
    categories: ['Ecommerce'],
    mainCategory: ['Web'],
    servicesCategory: [],
    servicesTags: [],
    linkId: 'delivery-tillster-platform',
    challenges: [{
      challenge:
  <div>
    Our client works with industry giants all over the world, so the main challenge has been delivering new features
    in a fast-paced and extremely agile environment.
  </div>,
      solution:
  <div>
    Together we helped develop and improve carryout and delivery strategies, as well as implement a store locator
    functionality that could be used by users all over the world.
    <br />
    <br />
    Implementing a store locator able to work with addresses from countries such as Thailand and the Philippines
    turned out to be one of the biggest challenges.
    <br />
    <br />
    The solution was to integrate Google API with Autocomplete and Place services. That meant querying place ids
    from autocomplete using PlaceServices, and detecting the type of address based on which other integrations were
    applied like showing a pin-drop on the map upon reverse-geocoding in case the address was incomplete or
    inaccurate after all queries performed.
    <br />
    <br />
    Optimizing the number of Google Place queries to reduce the costs was also part of the challenge.
    The conversion rate rose from 60% to 99.99%, regardless of users’ locations.
  </div>
    }]
  },
];

export default deliveryTillsterPlatformData;
