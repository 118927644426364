import React from "react";

const pgaData = [
  {
    images: [
      {
        small: require("../../assets/images/ourWork/pga/small/1.jpg"),
        large: require("../../assets/images/ourWork/pga/large/1.jpg")
      },
      {
        small: require("../../assets/images/ourWork/pga/small/2.jpg"),
        large: require("../../assets/images/ourWork/pga/large/2.jpg")
      },
      {
        small: require("../../assets/images/ourWork/pga/small/3.jpg"),
        large: require("../../assets/images/ourWork/pga/large/3.jpg")
      },
      {
        small: require("../../assets/images/ourWork/pga/small/4.jpg"),
        large: require("../../assets/images/ourWork/pga/large/4.jpg")
      },
      {
        small: require("../../assets/images/ourWork/pga/small/5.jpg"),
        large: require("../../assets/images/ourWork/pga/large/5.jpg")
      }
    ],
    cardImage: require("../../assets/images/ourWork/pga/card/pga-thumbnail.jpg"),
    bannerImage: require("../../assets/images/ourWork/pga/banner/banner.jpg"),
    title: "PGA Championship App",
    shortTitle: "PGA Championship App",
    id: "pga",
    hidden: false,
    logo: require("../../assets/images/ourWork/our-work-icons/our-clients/pga-logo.png"),
    shortDescription:
      "The PGA Champs App is a multi-tenant application that serves as a news outlet for the championships within The PGA tour",
    cardDescription:
      "The PGA Champs App is a multi-tenant application that serves as a news outlet for the championships within The PGA tour",
    description: (
      <div>
        The PGA Champs App is a multi-tenant mobile platform that serves as a news
        outlet for the championships within The PGA Tour. The app provides a
        wide range of media types, such as articles, videos, video stories, and
        live scoring provided via{" "}
        <a href={"https://www.brightspot.com/"} target={"_blank"}>
          Brightspot CMS
        </a>
        .
        <br /> <br />
        The app is meant to have users stay up to date with everything related
        to the championship, through live publishing of media and a powerful
        push notification experience. Additionally, the app integrates the
        SeatGeek SDK, allowing users to buy, sell, and manage tickets directly
        within the app. <br />
        <a
          href={
            "https://apps.apple.com/mm/app/pga-championships-official-app/id1501897280"
          }
        >
          App Store
        </a>{" "}
        <br />
        <a
          href={
            "https://play.google.com/store/apps/details?id=com.turner.pgachamp2013&pcampaignid=web_share"
          }
        >
          Play Store
        </a>
      </div>
    ),
    technologies: [
      "ReactNative",
      "Urban Airship SDK",
      "Gimbal",
      "Brightcove-player",
      "React Navigation",
      "Tanstack Query",
      "Firebase",
      "Firebase Analytics",
      "Lottie",
      "MobX-state-tree",
      "React-native-mmkv",
      "Google Mobile Ads",
      "GraphQL",
      "React-native-webview"
    ],
    services: [
      "Architecture",
      "Software Development",
      "Mobile Development",
      "QA & Automation",
      "Product Management"
    ],
    servicesCategory: ["App Development"],
    servicesTags: ["App Development"],
    categories: ["Featured", "Events"],
    mainCategory: ["Mobile", "Web"],
    linkId: "pga",
    challenges: [
      {
        challenge: (
          <div>
            1. <b>Cross-Platform Consistency</b>: Developing a cross-platform
            app that serves three different events from the PGA Tour, each
            requiring specific data, navigation, and push notifications,
            configurable through a CMS.
            <br /> <br />
            2. <b>Advanced Media Experience</b>: Delivering a powerful video
            media experience that supports horizontal and vertical videos,
            picture-in-picture functionality, and pre-roll ads. <br /> <br />
            3. <b>Geofencing Notifications</b>: Implementing the ability to send
            location-specific push notifications to users attending the
            championship on-site. <br /> <br />
            4. <b>Native Module Development</b>: Integrating the SeatGeek SDK,
            which lacks a React Native library, necessitating the creation of
            native modules for both iOS and Android. <br /> <br />
            5. <b>SSAI Pre-Roll Ads Support</b>: Modifying the existing
            Brightcove-player React Native library to support SSAI pre-roll ads,
            leading to the creation of a forked version with enhanced
            capabilities.
          </div>
        ),
        solution: (
          <div>
            1. The mobile application solution features a fully dynamic
            navigation system and screens that adapt based on navigation paths
            and item types fetched through a GraphQL CMS. This ensures
            flexibility and scalability, allowing content updates and new
            features without extensive code changes.
            <br /> <br />
            2. The video media experience was enhanced by extensively modifying
            the Brightcove-player React Native library to support SSAI pre-roll
            ads. This involved forking the library and adding necessary
            enhancements to meet the advertising requirements.
            <br /> <br />
            3. The mobile application uses the Urban Airship SDK and Gimbal
            adaptor to send location-based notifications. This integration
            ensures targeted messaging based on users' geographic locations,
            with Urban Airship handling efficient delivery and Gimbal providing
            precise location tracking.
            <br /> <br />
            4. To integrate SeatGeek SDK, we implemented a native modules for
            both iOS and Android, ensuring seamless functionality within the
            React Native framework despite the absence of an existing library.
          </div>
        )
      }
    ]
  }
];

export default pgaData;
