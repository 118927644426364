import React from 'react';

const homeMatchData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/home-match/small/01_small.jpg'),
        large: require('../../assets/images/ourWork/home-match/large/01.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/home-match/small/02_small.jpg'),
        large: require('../../assets/images/ourWork/home-match/large/02.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/home-match/small/04_small.jpg'),
        large: require('../../assets/images/ourWork/home-match/large/03.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/home-match/small/04_small.jpg'),
        large: require('../../assets/images/ourWork/home-match/large/04.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/home-match/small/05_small.jpg'),
        large: require('../../assets/images/ourWork/home-match/large/05.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/home-match/small/06_small.jpg'),
        large: require('../../assets/images/ourWork/home-match/large/06.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/home-match/small/07_small.jpg'),
        large: require('../../assets/images/ourWork/home-match/large/07.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/home-match/small/08_small.jpg'),
        large: require('../../assets/images/ourWork/home-match/large/08.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/home-match/card/homematch.jpg'),
    bannerImage: require('../../assets/images/ourWork/home-match/banner/banner.jpg'),
    title: 'Home Match | Real-Estate Mobile Platform',
    shortTitle: 'Home Match | Real-Estate Mobile Platform',
    id: 'Home_Match',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/cbre-logo.png'),
    shortDescription: 'CBRE’s HomeMatch is a mobile app built to match users to their ideal neighbourhood'
      + ' based on their specific criteria.',
    cardDescription: 'A mobile app built to match users to their ideal neighbourhood based on their specific criteria',
    description:
  <div>
    CBRE’s HomeMatch is a Real Estate mobile app built to match users to
    their ideal neighborhood based on their specific criteria.
    <br />
    <br />
    Its main purpose is to ease the search for those who are interested in renting or buying a new home.
    By asking a set of questions related to their ideal living environment,
    it provides them with a list of the top 10 neighborhoods
    that match their needs. From here, the user has all the necessary information they need for informed navigation.
    <br />
    <br />
    HomeMatch allows them to concentrate their search efforts on a small number of
    relevant properties that fit with their identity.
    <br />
    <br />
    Users have the option to register and save their favorite listings for later reference. Moreover,
    they can contact real estate agents to provide information on the properties they’ve listed,
    as well as set up appointments directly from the app.
  </div>,
    services: ['Product Strategy', 'UI/UX Design', 'Project Management', 'API Development', 'Software Development',
      'Devops & Infrastructure', 'QA & Automation'],
    technologies: ['React Native', 'ES@Next (6+)', 'Redux', 'Redux-Thunk', 'Redux-Persist', 'Styled Components',
      'Cross Platform', 'iOS', 'Android', 'Design', 'UX', 'UI'],
    results: [
      { percentage: '100M+', text: 'Revenue Growth' },
      { percentage: '2000+', text: 'End Users' },
      { percentage: '200K+', text: 'Transactions Closed' },
      { percentage: '100+', text: 'Real Estate Agencies' }],
    categories: ['Real Estate'],
    mainCategory: ['Mobile'],
    servicesCategory: ['UX/UI Design'],
    servicesTags: ['UX/UI Design', 'Cloud infrastructure'],
    linkId: 'home-match',
    challenges: [{
      challenge:
  <div>
    The goal of this project was to develop a native application from the ground up for both iOS and
    Android within an extremely tight schedule—a little over a month. We worked on implementing features such as:
    <br />
    <ul type="disc">
      <li>
        Complex questionnaire feature able to analyze the results and to generate accurate
        lists of matching neighborhoods for users;
      </li>
      <li>Push notifications;</li>
      <li>Maps integration;</li>
      <li>Social authentication.</li>
    </ul>
    Moreover, given that the release process was not to come into conflict with the development time,
    continuous deployment was required as well.
  </div>,
      solution:
  <div>
    Developing a highly performant cross-platform application able to support push notifications,
    maps integration, social authentication, as well as a native behavior within a few weeks,
    is only achievable if you push the limits of React Native.
    <br />
    <br />
    In order to integrate a stable continuous delivery, we used App Center; once set up,
    it allowed us to focus 100% on development without having to worry about manual releases.
    We also integrated feature branch releases.
    <br />
    <br />
    Given the complexity of the project,
    we made sure everyone was on the right track through daily meetings and one-week sprints.
    <br />
    <br />
    At the end of the project,
    we were proud to have been able to deliver a scalable and dynamic solution that met our client’s needs.
  </div>
    }]
  },
];

export default homeMatchData;
