import React from 'react';

const teachForwardData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/teachForward/small/1.png'),
        large: require('../../assets/images/ourWork/teachForward/large/1.png'),
      },
      {
        small: require('../../assets/images/ourWork/teachForward/small/2.png'),
        large: require('../../assets/images/ourWork/teachForward/large/2.png'),
      },
      {
        small: require('../../assets/images/ourWork/teachForward/small/3.png'),
        large: require('../../assets/images/ourWork/teachForward/large/3.png'),
      },
      {
        small: require('../../assets/images/ourWork/teachForward/small/1.png'),
        large: require('../../assets/images/ourWork/teachForward/large/1.png'),
      },
      {
        small: require('../../assets/images/ourWork/teachForward/small/2.png'),
        large: require('../../assets/images/ourWork/teachForward/large/2.png'),
      },
      {
        small: require('../../assets/images/ourWork/teachForward/small/3.png'),
        large: require('../../assets/images/ourWork/teachForward/large/3.png'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/teachForward/card/teachforward-thumbnail-min.png'),
    bannerImage: require('../../assets/images/ourWork/teachForward/banner/banner.jpg'),
    title: 'TeachForward | Analyzing Practice to Improve Teaching',
    shortTitle: 'TeachForward | Analyzing Practice to Improve Teaching',
    id: 'TeachForward',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/teachForward.png'),
    shortDescription: 'An intuitive web app for teachers to swiftly create and review standardized tests.'
      + ' Harness AngularJS and NestJS for smart, data-driven assessments that foster student success.',
    cardDescription: 'An intuitive web app for teachers to swiftly create and review standardized tests.',
    description:
      <div>
        TeachForward is an innovative web application designed to assist educators in reviewing and creating
        standardized tests with ease and efficiency. Leveraging the power of AngularJS and NestJS, TeachForward offers
        a user-friendly interface and a comprehensive suite of features that enable teachers to design tailor-made
        assessments that meet the needs of their students.
      </div>,
    services: ['Product Strategy', 'Software Development', 'QA & Automation', 'DevOps & Infrastructure'],
    technologies: ['AngularJs', 'Javascript/Typescript', 'NestJs', 'AWS'],
    servicesCategory: [],
    servicesTags: [],
    categories: ['Education', 'Web'],
    mainCategory: ['Education', 'Web'],
    linkId: 'teachForward',
    challenges: [
      {
        challenge:
          <div>
            Educators often face the daunting task of creating standardized tests that accurately assess their students'
            knowledge while ensuring fairness and adaptability. Moreover, teachers have limited time and resources to
            review and update their tests, leading to outdated or ineffective assessments.
          </div>,
        solution:
          <div>
            TeachForward addresses these challenges by providing a platform for teachers to collaborate, design, and
            review standardized tests in a centralized location. Our application utilizes AngularJS on the frontend,
            providing a seamless and responsive user experience. This powerful framework allows for the easy creation of
            dynamic, data-driven content, ensuring that test materials stay up-to-date and relevant.
            <br />
            <br />
            On the backend, NestJS - a modern, progressive framework - handles all data processing and management tasks.
            This enables TeachForward to offer robust features such as test versioning, question randomization, and
            real-time analytics to help teachers make informed decisions about their assessments.
            <br />
            <br />
            For deployment and scalability, TeachForward relies on the Amazon Web Services (AWS) infrastructure. By
            leveraging AWS's powerful suite of services, TeachForward ensures high availability, seamless performance,
            and secure data storage for users, no matter their location or the size of their institution.
            <br />
            <br />
            With TeachForward, educators can now streamline the test creation process and deliver more effective,
            data-driven assessments to their students.
          </div>
      }
    ]
  },
];

export default teachForwardData;
