import React from 'react';

const illuminateData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/illuminate/small/1.jpg'),
        large: require('../../assets/images/ourWork/illuminate/large/1.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/illuminate/small/2.png'),
        large: require('../../assets/images/ourWork/illuminate/large/2.png'),
      },
      {
        small: require('../../assets/images/ourWork/illuminate/small/3.png'),
        large: require('../../assets/images/ourWork/illuminate/large/3.png'),
      },
      {
        small: require('../../assets/images/ourWork/illuminate/small/1.jpg'),
        large: require('../../assets/images/ourWork/illuminate/large/1.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/illuminate/small/2.png'),
        large: require('../../assets/images/ourWork/illuminate/large/2.png'),
      },
      {
        small: require('../../assets/images/ourWork/illuminate/small/3.png'),
        large: require('../../assets/images/ourWork/illuminate/large/3.png'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/illuminate/card/Illuminate-thumbnail-min.jpg'),
    bannerImage: require('../../assets/images/ourWork/illuminate/banner/banner.jpg'),
    title: 'Illuminate | Education technology company',
    shortTitle: 'Illuminate | Education technology company',
    id: 'Illuminate',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/illuminate.png'),
    shortDescription: 'Illuminate Education is an education technology company that provides data and assessment '
      + 'management tools, professional development resources, and student information systems for K-12 schools and '
      + 'districts.',
    cardDescription: 'An education technology company that provides data and assessment '
      + 'management tools',
    description:
      <div>
        {' '}
        <a href="https://www.illuminateed.com/" target="_blank" rel="noreferrer">Illuminate Education </a>
        {' '}
        is an education technology company that provides data and assessment management tools,
        professional development resources, and student information systems for K-12 schools and districts.
        <br />
        {' '}
        <br />
        The company’s mission is to transform education by equipping educators with the data, tools, and resources
        they need to improve student outcomes.
      </div>,
    services: ['Product Strategy', 'Software Development', 'QA & Automation', 'DevOps & Infrastructure'],
    technologies: ['Java', 'AngularJS', 'React', 'Node.js', 'PostgreSQL', 'MongoDB', 'AWS', 'REST APIs',
      'Single sign-on (SSO) integration', 'Data warehousing and analytics tools', 'Mobile app development',
      'Learning management systems (LMS)', 'Student response systems (SRS)'],
    servicesCategory: [],
    servicesTags: [],
    categories: ['Education', 'Web'],
    mainCategory: ['Education', 'Web'],
    linkId: 'illuminate',
    challenges: [
      {
        challenge:
          <div>
            One of the biggest challenges in education today is ensuring that students are receiving the support they
            need to succeed. This requires a deep understanding of each student’s unique needs, as well as the ability
            to quickly analyze and act on data to address those needs.
            <br />
            <br />
            However, collecting and analyzing student data can be a complex and time-consuming process, especially for
            schools and districts with limited resources.
            <br />
            <br />
            Our challenge was to create a platform that would make it easier for educators to collect, analyze, and act
            on student data. We needed to develop a user-friendly system that would allow educators to quickly and
            easily input data, generate reports, and identify areas where students need extra support.
            <br />
            <br />
            Additionally, we needed to ensure that the system was secure and compliant with relevant regulations.
          </div>,
        solution:
          <div>
            We focused on developing a platform that would provide educators with the tools and resources they need to
            improve student outcomes.
            <br />
            <br />
            Our platform includes a wide range of features, including data visualization tools, automated reporting, and
            predictive analytics. We integrated with various student information systems to provide a unified view of
            student data. This allows educators to quickly and easily access the information they need to make informed
            decisions about student support.
            <br />
            <br />
            We also developed a range of professional development resources to help educators build the skills they need
            to effectively use data to improve student outcomes. Our resources include online courses, webinars, and
            on-site training sessions.
            <br />
            <br />
            We leveraged the latest technologies and best practices to ensure that our platform is secure, scalable, and
            compliant with relevant regulations. We use automated testing and continuous integration to ensure that our
            platform is always up to date and performing at its best.
            <br />
            <br />
            Overall, our platform is designed to provide educators with the data, tools, and resources they need to
            improve student outcomes and transform education.
          </div>
      }
    ]
  },
];

export default illuminateData;
