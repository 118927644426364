import React from 'react';

const livebuyData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/livebuy/small/1.jpg'),
        large: require('../../assets/images/ourWork/livebuy/large/1.jpg')
      },
      {
        small: require('../../assets/images/ourWork/livebuy/small/2.jpg'),
        large: require('../../assets/images/ourWork/livebuy/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/livebuy/small/3.jpg'),
        large: require('../../assets/images/ourWork/livebuy/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/livebuy/small/4.jpg'),
        large: require('../../assets/images/ourWork/livebuy/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/livebuy/small/5.jpg'),
        large: require('../../assets/images/ourWork/livebuy/large/5.jpg')
      },
    ],
    cardImage: require('../../assets/images/ourWork/livebuy/card/livebuy-thumbnail-min.jpg'),
    bannerImage: require('../../assets/images/ourWork/livebuy/banner/banner.jpg'),
    title: 'Livebuy',
    shortTitle: 'Livebuy',
    id: 'Livebuy',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/livebuy.png'),
    shortDescription: 'Livebuy is a live commerce engine. It boosts customer engagement and sales.',
    cardDescription: 'A live commerce engine',
    description:
  <div>
    Livebuy is a live commerce engine. It boosts customer engagement and sales by allowing ecommerce businesses
    owners to promote their products with shoppable live video streams - directly from their shops.
  </div>,
    technologies: ['React', 'Javascript / Typescript', 'React Native', 'Mobx', 'Fastlane',
      'FirebasePushNotifications', 'MUX video API integration'],
    services: ['Product Strategy', 'Software Development', 'DevOps & Infrastructure'],
    servicesCategory: ['App Development'],
    servicesTags: ['App Development'],
    categories: ['Featured', 'Events', 'Ecommerce'],
    mainCategory: ['Mobile', 'Web'],
    linkId: 'livebuy',
    challenges: [{
      challenge:
  <div>
    Help ecommerce platforms boost their engagement rates & sales by selling and products live
  </div>,
      solution:
  <div>
    Web dashboard-like application to help ecommerce business owners build their creator teams and start going live.
    Mobile app which enables creators to go live and promote products while live streaming.
    Embeddable web widget to allow business owners to stream live from their websites / online shops.
    <br />
    {' '}
    <br />
    We’ve implemented a web dashboard app using React, enabling clients to manage and organize creators,
    influencers, past or future live shows - all from one place. The Livebuy Dashboard also provides a set of
    analytics for understanding traffic, engagement & conversion rates - all crucial information for running
    a successful ecommerce business.
    <br />
    {' '}
    <br />
    The Livebuy Widget  allows shops to build their own UI for presenting live shows on their websites / web apps.
    The Livebuy Widget API allows shops to get all the information of a Widget configured in the Livebuy Dashboard
    using an automatically generated API key.
    <br />
    {' '}
    <br />
    The Livebuy Player allows users to watch one shop’s livestreams. The viewers can interact with the show owners
    using a built in chat & add promoted products to cart.
    <br />
    {' '}
    <br />
    Creators & influencers can start promoting products in a live show with a tap of a finger using the Livebuy
    Creator App. The Creator App is a mobile application built with React Native which allows creators to log in
    into their designated shops and start scheduling live shows. The creator can use this app to live stream a
    show directly on the shop’s website.
  </div>,
    }]
  },
];

export default livebuyData;
