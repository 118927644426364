import React from 'react';

const demcoDiscoverLocalData = [
  {
    images: [{
      small: require('../../assets/images/ourWork/demco-discover-local/small/0.jpg'),
      large: require('../../assets/images/ourWork/demco-discover-local/large/0.jpg'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-local/small/1.jpg'),
      large: require('../../assets/images/ourWork/demco-discover-local/large/1.jpg'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-local/small/2.jpg'),
      large: require('../../assets/images/ourWork/demco-discover-local/large/2.jpg'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-local/small/3.jpg'),
      large: require('../../assets/images/ourWork/demco-discover-local/large/3.jpg'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-local/small/4.jpg'),
      large: require('../../assets/images/ourWork/demco-discover-local/large/4.jpg'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-local/small/5.jpg'),
      large: require('../../assets/images/ourWork/demco-discover-local/large/5.jpg'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-local/small/6.jpg'),
      large: require('../../assets/images/ourWork/demco-discover-local/large/6.jpg'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-local/small/7.jpg'),
      large: require('../../assets/images/ourWork/demco-discover-local/large/7.jpg')
    }],
    cardImage: require('../../assets/images/ourWork/demco-discover-local/card/discover-local.jpg'),
    bannerImage: require('../../assets/images/ourWork/demco-discover-local/banner/banner.jpg'),
    title: 'Demco DiscoverLocal | Online Library Web Platform',
    shortTitle: 'Demco DiscoverLocal | Online Library Web Platform',
    id: 'Demco_Software_Web',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/demco-min.png'),
    shortDescription: `Demco Software partnered with MCRO to develop customized, innovative solutions for public 
    libraries. Their DiscoverLocal platform is designed to improve discoverability and SEO for library resources.`,
    cardDescription: 'A multi-tenant system built to help public libraries manage their content and data',
    description:
  <div>
    <a href="https://www.demcosoftware.com" target="_blank" rel="noreferrer">Demco Software</a>
    {' '}
    partnered with MCRO to develop customized, innovative solutions for public libraries.
    Their
    {' '}
    <a href="https://www.demcosoftware.com/products/discoverlocal/" target="_blank" rel="noreferrer">
      DiscoverLocal
    </a>
    {' '}
    platform is designed to improve discoverability and SEO for library resources.
    <br />
    <br />
    The platform works by geo-tagging records and events uploaded by libraries and transforming them into links
    which are harvested by search engines. This results in better visibility to search engines,
    making library resources easily accessible and ultimately more visible.
    <br />
    <br />
    MCRO worked on integrating a number of useful features such as: library rooms reservations,
    events scheduling & signup,
    books catalog, e-reader integration, books reservation, accounts management and patron profile management.
    <br />
    <br />
    An Amazon Alexa set of skills for Amazon Echo is used to allow users to place holds and renew items,
    book reservations on the fly,
    register to library events or simply get information about the library and its resources.
    <br />
    <br />
    MCRO is proud to have helped Demco Software develop the web application and API,
    offering support from Product Development and Design to Back-end Engineering, DevOps,
    Front-end Engineering and Security, leaving behind a great product.
  </div>,
    services: ['Product Strategy', 'UI/UX Design', 'Project Management', 'API Development', 'Web Development',
      'Devops & Infrastructure', 'QA & Automation'],
    technologies: ['PHP', 'Symfony3 Framework', 'REST API', 'Drupal CMS', 'VueJS', 'GraphQL', 'Elastic Search',
      'PostgresSQL', 'Docker', 'Multi-tenant Platform', 'AWS', 'RDS', 'AWS Lambda', 'AWS Alexa', 'ILS Integration',
      'Kafka', 'Automated Deployments', 'Security Testing', 'UI/UX', 'Product Development'],
    results: [
      { percentage: '100K+', text: 'Users' },
      { percentage: '10K+', text: 'Events Organized' },
      { percentage: '100+', text: 'Universities' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Education'],
    mainCategory: ['Web'],
    servicesCategory: ['Web Development', 'Ideation & Product Strategy'],
    servicesTags: ['Web Development', 'Cloud Infrastructure', 'Ideation and product strategy'],
    linkId: 'demco-discover-local',
    challenges: [{
      challenge:
  <div>
    Demco understood the value a custom library web-portal and mobile app could bring to their customers and
    decided to build a platform where users could find relevant books,
    schedule or register for events, reserve rooms and so on.
    <br />
    <br />
    Since the number of libraries that sign up for Demco services was likely to increase,
    and each library had individual preferences, our client requested a reliable,
    customizable and scalable software solution that could accommodate all their customers.
    <br />
    <br />
    Demco needed to develop the solution from the ground up, including the UI/UX, technical and QA aspects.
    MCRO is proud to have been able to help bring a complex software solution to life.
  </div>,
      solution:
  <div>
    During the first phase of the project, we began by assisting Demco through a strategic meeting dedicated
    to defining technical specifications.
    This helped us outline an initial realistic product development and release schedule.
    <br />
    <br />
    The system architecture that we agreed-upon was a multi-tenant architecture.
    This way the software is designed to provide every library a dedicated share of the software instance,
    including individual data, customizable functionality and configuration.
    <br />
    <br />
    Regardless of the time-zone differences,
    we managed to maintain effective communication between our client, designers, devops and developers.
    <br />
    <br />
    During the development process, every single feature that was pushed to the codebase would apply
    to all the tenants within minutes due to the continuous delivery system built in Jenkins.
    <br />
    <br />
    This made all the libraries happy, which means our client was happy too.
    <br />
    <br />
    We also focused strongly on security and access control,
    allowing admins to assign roles based on the need to access sensitive information.
    <br />
    <br />
    We are proud to have met the challenge and to have helped Demco develop such a complex software solution.
  </div>
    }],
  },
];

export default demcoDiscoverLocalData;
