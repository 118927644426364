import React from 'react';

const pieData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/pie-framework/small/1.jpg'),
        large: require('../../assets/images/ourWork/pie-framework/large/1.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/pie-framework/small/2.jpg'),
        large: require('../../assets/images/ourWork/pie-framework/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/pie-framework/small/3.jpg'),
        large: require('../../assets/images/ourWork/pie-framework/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/pie-framework/small/4.jpg'),
        large: require('../../assets/images/ourWork/pie-framework/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/pie-framework/small/5.jpg'),
        large: require('../../assets/images/ourWork/pie-framework/large/5.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/pie-framework/card/pie-framework.jpg'),
    bannerImage: require('../../assets/images/ourWork/pie-framework/banner/banner.jpg'),
    title: 'Pie Framework | Open Source Library',
    shortTitle: 'Pie Framework | Open Source Library',
    id: 'Pie_Framework',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/pie-logo.png'),
    shortDescription: 'Portable Interactions and Elements (PIE) is an Open Source framework for building,'
      + ' distributing and rendering re-usable UI elements and interactions.',
    cardDescription: 'Open-source framework focused on UI elements and interactions',
    description:
  <div>
    {' '}
    <a href="https://pie-framework.org/" target="_blank" rel="noreferrer">
      Portable Interactions and
      Elements (PIE)
    </a>
    {' '}
    is an Open Source framework developed as a means for making high-quality assessment content available to educators.
    <br />
    <br />
    It provides future-proof solutions that seamlessly integrate with the platforms used by schools and universities,
    allowing them to configure tests and quizzes for their students and analyze results.
    <br />
    <br />
    Here’s how it works:
    <br />
    <br />
    <strong>For Developers </strong>
    {' '}
    - By using web development best practices and tools,
    it allows developers to easily create and distribute re-usable question types
    <br />
    <br />
    <strong>For content publishers </strong>
    {' '}
    - By using simple or advanced assessment interactions,
    users have the ability to create content and distribute it to their customers.
    <br />
    <br />
    Our mission is to ultimately help educators have better access to high-quality and more interactive
    assessment interactions. MCRO is proud to be a part of this project and to play a role in the rising
    digitization of the education industry.
  </div>,
    services: ['UI/UX Design', 'Product Management', 'Software Development'],
    technologies: ['Typescript + ES@Next (6+)', 'Webpack 2+', 'React', 'Enzyme', 'Jest', 'Firebase',
      'Firestore Web Components', 'MathML', 'Latex'],
    results: [
      { percentage: '10+', text: 'Universities' },
      { percentage: '10K+', text: 'Downloads' },
      { percentage: '200K', text: 'Students' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Financial', 'Education', 'Open Source'],
    mainCategory: ['Web', 'Opensource'],
    servicesCategory: ['Cloud Infrastructure'],
    servicesTags: ['Cloud Infrastructure', 'Web Development'],
    linkId: 'pie-framework',
    challenges: [
      {
        challenge:
  <div>
    Our biggest challenge was to develop a scalable and comprehensive framework for handling author created
    questionnaires and interactions in the UI.
    <br />
    <br />
    We needed to help build an user-friendly platform that would allow its users to create advanced
    assessment interactions in an easy, intuitive way.
    <br />
    <br />
    Moreover, the platform needed to be developer-friendly, allowing its users to deliver responsive,
    interactive question types through HTML5 and modern Javascript development.
  </div>,
        solution:
  <div>
    The challenge we were faced with was to design and implement a highly modular,
    CLI-compatible platform using React and Webpack.
    <br />
    <br />
    We had to facilitate building and connecting components on the fly in a performant manner
    with a streamlined and easy to use user experience - on both ends.
    <br />
    <br />
    MCRO is proud to have been able to meet our client’s expectations
    and to deliver timely and effective solutions
  </div>
      }
    ]
  },
];

export default pieData;
