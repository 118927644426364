import docviserData from "./portofolio/docviser";
import docviserAiData from "./portofolio/docviser-generative-ai";
import myaData from "./portofolio/mya";
import livebuyData from "./portofolio/livebuy";
import parkingMatchData from "./portofolio/parking-match";
import stitchData from "./portofolio/stitch";
import sigoraSolarData from "./portofolio/sigora-solar";
import tillsterData from "./portofolio/tillster";
import conferenceCompassData from "./portofolio/conferenceCompass";
import dstoqExchangeData from "./portofolio/dstoqExchange";
import pieData from "./portofolio/pie";
import deliveryTillsterPlatformData from "./portofolio/deliveryTillsterPlatform";
import blockbitsIoData from "./portofolio/blockbitsIo";
import kaitoRfpData from "./portofolio/kaitoRfp";
import mariChefData from "./portofolio/mariChef";
import smashcutData from "./portofolio/smashcut";
import quandoraData from "./portofolio/quandora";
import demcoDiscoverMobileData from "./portofolio/demcoDiscoverMobile";
import demcoDiscoverLocalData from "./portofolio/demcoDiscoverLocal";
import outcomes4meData from "./portofolio/outcomes4me";
import falconGpsData from "./portofolio/falconGps";
import homeMatchData from "./portofolio/homeMatch";
import squeezerData from "./portofolio/squeezer";
import acer from "./portofolio/acer";
import illuminateData from "./portofolio/illuminate";
import teachForwardData from "./portofolio/teachForward";
import herewith from "./portofolio/herewith";
import pgaData from "./portofolio/pga";
import apNewsData from "./portofolio/ap-news";
import amplifyData from "./portofolio/amplify";
import caretagData from "./portofolio/caretag";
import activatorData from "./portofolio/activator";

const data = [
  ...docviserData,
  ...pgaData,
  ...herewith,
  ...apNewsData,
  ...docviserAiData,
  ...amplifyData,
  ...activatorData,
  ...caretagData,
  ...teachForwardData,
  ...illuminateData,
  ...stitchData,
  ...parkingMatchData,
  ...myaData,
  ...livebuyData,
  ...acer,
  ...sigoraSolarData,
  ...tillsterData,
  ...conferenceCompassData,
  ...dstoqExchangeData,
  ...pieData,
  ...deliveryTillsterPlatformData,
  ...blockbitsIoData,
  ...kaitoRfpData,
  ...mariChefData,
  ...smashcutData,
  ...quandoraData,
  ...demcoDiscoverMobileData,
  ...demcoDiscoverLocalData,
  ...outcomes4meData,
  ...falconGpsData,
  ...homeMatchData,
  ...squeezerData
];

export default data;
