import React from 'react';

const demcoDiscoverMobileData = [
  {
    images: [{
      small: require('../../assets/images/ourWork/demco-discover-mobile/small/0.png'),
      large: require('../../assets/images/ourWork/demco-discover-mobile/large/0.png'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-mobile/small/1.png'),
      large: require('../../assets/images/ourWork/demco-discover-mobile/large/1.png'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-mobile/small/2.png'),
      large: require('../../assets/images/ourWork/demco-discover-mobile/large/2.png'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-mobile/small/3.png'),
      large: require('../../assets/images/ourWork/demco-discover-mobile/large/3.png'),
    }, {
      small: require('../../assets/images/ourWork/demco-discover-mobile/small/4.png'),
      large: require('../../assets/images/ourWork/demco-discover-mobile/large/4.png'),
    }],
    cardImage: require('../../assets/images/ourWork/demco-discover-mobile/card/demco-app.jpg'),
    bannerImage: require('../../assets/images/ourWork/demco-discover-mobile/banner/banner.jpg'),
    title: 'Demco DiscoverLocal (Mobile)| Online Library Mobile App',
    shortTitle: 'Demco DiscoverLocal (Mobile)| Online Library Mobile App',
    id: 'Demco_Software_Mobile',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/demco-min.png'),
    shortDescription: `Demco DiscoverLocal (Mobile) is a mobile app built to digitize the way communities engage with 
    their public libraries. From searching library resources, to reserving rooms and registering to events, the 
    application provides full support for all library related activities.`,
    cardDescription: 'A mobile app built to digitize the way communities engage with their public libraries',
    description:
  <div>
    <a href="https://www.demcosoftware.com" target="_blank" rel="noreferrer">
      Demco Software’s DiscoverLocal
    </a>
    {' '}
    mobile app is one of the mobile solutions that Demco Software offers to public libraries.
    <br />
    <br />
    From searching library resources, to reserving rooms and registering to events,
    the application provides full support for all library related activities.
    <br />
    <br />
    The platform is a multi-tenant system that serves native cross-device applications using React Native.
    <br />
    <br />
    MCRO is proud to have helped Demco Software's engineering team to develop the application
    and to take a complex software solution from design to production.
  </div>,
    services: ['Product Strategy', 'UI/UX Design', 'Project Management', 'Mobile Development',
      'Devops & Infrastructure', 'QA & Automation'],
    technologies: ['React Native', 'ES@Next (6+)', 'Redux', 'React Navigation', 'Styled Components', 'iOS', 'Android',
      'Multi-tenant', 'Cross Platform', 'FastLane', 'HockeyApp Deployments', 'Automated Builds', 'UI/UX',
      'Product Development'],
    results: [
      { percentage: '100K+', text: 'Users' },
      { percentage: '10K+', text: 'Events Organized' },
      { percentage: '100+', text: 'Universities' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Education'],
    mainCategory: ['Mobile'],
    servicesCategory: ['Ideation & Product Strategy'],
    servicesTags: ['Ideation and product strategy', 'App Development'],
    linkId: 'demco-discover-mobile',
    challenges: [{
      challenge:
  <div>
    One of Demco Software’s requirements was for the app to be fully customizable.
    This being said, our biggest challenge was to build a multi-tenant application
    that would allow clients to customize it in terms of features, branding and UI details.
  </div>,
      solution:
  <div>
    In order to build a multi-tenant app that would allow for customization,
    we ended up creating a proper structure and configuration files for all the tenants.
    These files were used for all the possible differences requested by different libraries.
  </div>
    }]
  },
];

export default demcoDiscoverMobileData;
