import React from 'react';

const acerData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/acer/small/1.jpg'),
        large: require('../../assets/images/ourWork/acer/large/1.jpg')
      },
      {
        small: require('../../assets/images/ourWork/acer/small/2.jpg'),
        large: require('../../assets/images/ourWork/acer/large/2.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/acer/card/card.jpg'),
    bannerImage: require('../../assets/images/ourWork/acer/banner/banner.jpg'),
    title: 'Acer',
    shortTitle: 'Acer',
    id: 'Acer',
    hidden: true,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/acer.png'),
    shortDescription: 'A live commerce engine',
    cardDescription: 'A live commerce engine',
    description:
  <div>
    Description
  </div>,
    technologies: ['React', 'Javascript / Typescript', 'React Native', 'Mobx', 'Fastlane',
      'FirebasePushNotifications', 'MUX video API integration'],
    services: ['Product Strategy', 'Software Development', 'DevOps & Infrastructure'],
    servicesCategory: ['App Development'],
    servicesTags: ['App Development'],
    categories: [],
    mainCategory: ['Mobile'],
    linkId: 'acer',
    challenges: [{
      challenge:
  <div>
    Challenge
  </div>,
      solution:
  <div>
    Solution
  </div>,
    }]
  },
];

export default acerData;
