import React from 'react';

const stitchData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/stitch/small/1.jpg'),
        large: require('../../assets/images/ourWork/stitch/large/1.jpg')
      },
      {
        small: require('../../assets/images/ourWork/stitch/small/2.jpg'),
        large: require('../../assets/images/ourWork/stitch/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/stitch/small/3.jpg'),
        large: require('../../assets/images/ourWork/stitch/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/stitch/small/4.jpg'),
        large: require('../../assets/images/ourWork/stitch/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/stitch/small/5.jpg'),
        large: require('../../assets/images/ourWork/stitch/large/5.jpg')
      },
    ],
    cardImage: require('../../assets/images/ourWork/stitch/card/card.jpg'),
    bannerImage: require('../../assets/images/ourWork/stitch/banner/banner.jpg'),
    title: 'Stitch',
    shortTitle: 'Stitch',
    id: 'Stitch',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/stitch.png'),
    shortDescription: 'Stitch is a cognitive behavior therapy app with a probiotic supplements subscription that '
      + 'offers daily support in building habits for long-lasting stress management.',
    cardDescription: 'A cognitive behavior therapy app with a probiotic supplements subscription that offers daily '
      + 'support in building habits for long-lasting stress management.',
    description:
  <div>
    Stitch is a cognitive behavior therapy app with a probiotic supplements subscription that offers daily support
    in building habits for long-lasting stress management.
    <br />
    The app is designed to track the probiotics intake, to help the user set up for success in the morning and reflect
    on the entire daily activity in the evening by creating a personalized plan with content and tools designed
    for the mind and body.
  </div>,
    technologies: ['React Native', 'Expo', 'React', 'Typescript', 'Mobx', 'Fastlane',
      'Firebase', 'Strapi CMS', 'AWS', 'Amplitude', 'Stripe', 'HealthKit', 'NodeJS', 'NestJS', 'NextJS', 'Redis'],
    // eslint-disable-next-line max-len
    services: ['Product Strategy', 'Software Development', 'QA & Automation', 'DevOps & Infrastructure', 'UI/UX Design'],
    servicesCategory: ['App Development'],
    servicesTags: ['App Development'],
    categories: ['Featured', 'Healthcare', 'Ecommerce'],
    mainCategory: ['Mobile', 'Web'],
    linkId: 'stitch',
    challenges: [{
      challenge:
  <div>
    Create a daily plan for every individual based on a smart recommendation system.
    <br />
    Create an event system to handle the Stripe subscriptions integration with full coverage for all required events.
    <br />
    Handle in time delivery of the probiotic together with the app subscription.
    <br />
    Determine the user stress levels across the day and use the health data from health devices to adapt the
    recommendation plan.
    <br />
    Implement a reward system for users to encourage them to respect the daily plan
  </div>,
      solution:
  <div>
    A very responsive cross-platform mobile application built with ReactNative and the latest tech stack.
    <br />
    A complete flow of monthly subscription using Stripe and delivery of the pro-biotic integration using Flowspace
    to have the users receive the product in a timely manner.
    <br />
    A very intuitive and friendly user experience to help the users achieve the goals and work on a daily plan filled
    with reminders and activities.
    <br />
    Integrate the HealthKit from iOS and collect heartbeat, workout activity, steps count and other relevant
    information from iWatches in order to determine the trajectory of the stress levels across the daily activity.
    <br />
    Eg. A high level of heartbeat without any workout activity or movement can signal a high level of stress.
    <br />
    Adapting a daily plan with personalized recommendations based on relevant data coming from health.
    Implementation of a scalable infrastructure in AWS (ECS, S3, CloudFront, CodePipeline, RDS DB, Redis)  to handle
    a big growth in usage.
    <br />
    {' '}
    <br />
    An ultra fast web application built using NextJS to handle the users onboarding and subscription flow.
    <br />
    {' '}
    <br />
    Hubspot, Facebook, Hotjar integration for marketing automation.
    Strapi used to manage the web and mobile applications content.
    Amplitude integration in order to create relevant analytics that track complete user flows and heatmaps.
  </div>,
    }]
  },
];

export default stitchData;
