import React from 'react';

const tillsterData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/kiosk-tillster-platform/small/1.jpg'),
        large: require('../../assets/images/ourWork/kiosk-tillster-platform/large/1.jpg')
      },
      {
        small: require('../../assets/images/ourWork/kiosk-tillster-platform/small/2.jpg'),
        large: require('../../assets/images/ourWork/kiosk-tillster-platform/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/kiosk-tillster-platform/small/3.jpg'),
        large: require('../../assets/images/ourWork/kiosk-tillster-platform/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/kiosk-tillster-platform/small/4.jpg'),
        large: require('../../assets/images/ourWork/kiosk-tillster-platform/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/kiosk-tillster-platform/small/5.jpg'),
        large: require('../../assets/images/ourWork/kiosk-tillster-platform/large/5.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/kiosk-tillster-platform/small/7.jpg'),
        large: require('../../assets/images/ourWork/kiosk-tillster-platform/large/7.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/kiosk-tillster-platform/small/6.jpg'),
        large: require('../../assets/images/ourWork/kiosk-tillster-platform/large/6.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/kiosk-tillster-platform/card/ph-kiosk.jpg'),
    bannerImage: require('../../assets/images/ourWork/kiosk-tillster-platform/banner/banner.jpg'),
    title: 'Tillster Kiosk Platform | Food E-Buy',
    shortTitle: 'Tillster Kiosk Platform',
    id: 'Kiosk_Tillster_Platform',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/tillster-min.png'),
    shortDescription: 'A custom-built Kiosk platform used by popular restaurants and fast-food chains to optimise'
      + ' their services and improve customer experience.',
    cardDescription: 'A custom-built Kiosk platform for restaurants & fast-food chains',
    description:
  <div>
    <a href="https://tillster.com" target="_blank" rel="noreferrer">Tillster</a>
    {' '}
    is a global leader in self-service technologies, powering online ordering and delivery solutions
    for popular restaurant brands all over the world.
    <br />
    {' '}
    <br />
    {' '}
    With over 40000 stores implemented in over 20 countries,
    our client serves well-known brands such as Burger King, Pizza Hut, KFC, and Taco Bell to name a few.
    <br />
    {' '}
    <br />
    Tillster has teamed with MCRO to develop a
    {' '}
    <a href="https://www.tillster.com/self-service-kiosk" target="_blank" rel="noreferrer">Kiosk</a>
    {' '}
    application that would assist restaurant & fast-food customers all the way through the ordering process.
  </div>,
    services: ['Software Development', 'Project/Product Management', 'QA & Automation', 'DevOps'],
    technologies: ['Angular', 'CSS3', 'Webpack', 'ES6', 'Sass', 'Responsive', 'Kiosk', 'REST', 'Multi-tenant Platform',
      'Cross Platform', 'UX', 'UI'],
    results: [
      { percentage: '$100K', text: 'Daily Transactions' },
      { percentage: '15%', text: 'Cost Reduction' },
      { percentage: '75%', text: 'Customer Retention' },
      { percentage: '50%', text: 'Sales Velocity Increase' }],
    testimonial: {
      imageSrc: require('../../assets/images/ourWork/kiosk-tillster-platform/dana-cordes-tilster_1-min.png'),
      quote: 'Our partnership with MCRO has been going strong since 2016. What strikes me the most is their '
        + 'dedication and commitment to delivering great work. They are invested in the project and care '
        + 'about the outcome as much as we do. I would definitely recommend working with MCRO as they are '
        + 'extremely competent and thorough about everything.',
      author: 'Dana Cordes',
      position: 'Director of Engineering at Tillster' },
    categories: ['Featured', 'Ecommerce'],
    mainCategory: ['Mobile'],
    servicesCategory: ['App Development'],
    servicesTags: ['App Development', 'Cloud infrastructure', 'Ideation and product strategy'],
    linkId: 'kiosk-tillster-platform',
    challenges: [{
      challenge:
  <div>
    The challenge we were faced with was coming up with a solution that would allow us to customize and develop on top
    of a massive multi-tenant platform without them interfering with one another.
    {' '}
    <br />
    {' '}
    <br />
    In order to deliver the best possible customer experience, we needed to create a platform that would guarantee
    fast payment and order processing in an eye-catching UI environment.
  </div>,
      solution:
  <div>
    We developed a fully customizable platform that can hold multiple brand identities and deliver a top-notch
    self-service experience to the end-user.
    {' '}
    <br />
    <br />
    How we did it was by implementing a multi-tenant infrastructure that promotes code re-usage across multiple brands
    using a combination of build-time configurations, services,
    and directives with minimal impact and cost of maintenance.
    <br />
    {' '}
    <br />
    With POS and API integration, we fully integrated the application with the user’s account, ensuring that loyalty
    points and other benefits are properly credited.
  </div>
    }]
  },
];

export default tillsterData;
