import React from 'react';

const myaData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/mya/small/1.jpg'),
        large: require('../../assets/images/ourWork/mya/large/1.jpg')
      },
      {
        small: require('../../assets/images/ourWork/mya/small/2.jpg'),
        large: require('../../assets/images/ourWork/mya/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/mya/small/3.jpg'),
        large: require('../../assets/images/ourWork/mya/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/mya/small/4.jpg'),
        large: require('../../assets/images/ourWork/mya/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/mya/small/5.jpg'),
        large: require('../../assets/images/ourWork/mya/large/5.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/mya/card/card.jpg'),
    bannerImage: require('../../assets/images/ourWork/mya/banner/banner.jpg'),
    title: 'MYA',
    shortTitle: 'MYA',
    id: 'MYA',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/apothera.png'),
    shortDescription: 'MYA is a mobile application that provides help in the pharmacy system, by ordering and '
      + 'delivering prescriptions and medications to the customer.',
    cardDescription: 'A mobile application that provides help in the pharmacy system, by ordering and delivering '
      + 'prescriptions and medications to the customer. ',
    description:
  <div>
    MYA is a mobile application that provides help in the pharmacy system, by ordering and delivering prescriptions
    and medications to the customer. The app is designed to improve and facilitate the process of buying medications
    for people with active insurance.
    <br />
    {' '}
    <br />
    The app has two main flows, one created to scan the prescription received from the doctor and the other one to
    create your own prescription, both of them having the same result: having your medication delivered at your door.
    <br />
    {' '}
    <br />
    The MCRO team developed the scanning system for the prescription’s codes and for the medication’s pzn,
    integrating the
    <a href="https://scanacs.de/" target="_blank" rel="noreferrer">{' Scanacs '}</a>
    and
    <a href="https://www.gematik.de/" target="_blank" rel="noreferrer">{' Gematik '}</a>
    platforms for processing and use of medical data.
  </div>,
    technologies: ['TypeScript', 'React Native', 'Graphql', 'Mobx', 'Expo build', 'EAS', 'Fastlane', 'Firebase'],
    services: ['Product Strategy', 'Software Development', 'DevOps & Infrastructure'],
    servicesCategory: ['App Development'],
    servicesTags: ['App Development'],
    categories: ['Featured', 'Healthcare'],
    mainCategory: ['Mobile'],
    linkId: 'mya',
    challenges: [{
      challenge:
  <div>
    1. Develop fully customizable mobile solutions for integration between different platforms.
    <br />
    {' '}
    <br />
    2. Create a custom and multifunctional scanning system for the prescription’s codes and for the medication’s pzn.
    <br />
    {' '}
    <br />
    3. Handling dynamic links via notifications and emails.
  </div>,
      solution:
  <div>
    1. We’ve implemented a highly customizable white label app using React Native, making sure that the design remains
    consistent between platforms, without changing the native aspect. We’ve pushed the limits of Fastlane, Expo CI/CD
    and make the process of deploying the app faster and automated with a minimal cost of maintenance.
    <br />
    {' '}
    <br />
    2. We’ve used an external library for mapping the result of the code obtained by scanning the code in a defined
    area. Integrating two different platforms, Scanacs and Dematik we were able to get all the necessary information for
    creating a new order, based on the prescription’s code, and also all the medication’ informations from the pzn.
    <br />
    {' '}
    <br />
    3. Integrating the Firebase system we were able to create fully customized dynamic links having the same behavior
    both on the email and on the notification. Those links were created based on the user's data and on his needs.
  </div>,
    }]
  },
];

export default myaData;
