import React from "react";

const activatorData = [
  {
    images: [
      {
        small: require("../../assets/images/ourWork/activator/small/1.jpg"),
        large: require("../../assets/images/ourWork/activator/large/1.jpg")
      },
      {
        small: require("../../assets/images/ourWork/activator/small/2.jpg"),
        large: require("../../assets/images/ourWork/activator/large/2.jpg")
      },
      {
        small: require("../../assets/images/ourWork/activator/small/3.jpg"),
        large: require("../../assets/images/ourWork/activator/large/3.jpg")
      }
    ],
    cardImage: require("../../assets/images/ourWork/activator/card/activator-thumbnail.jpg"),
    bannerImage: require("../../assets/images/ourWork/activator/banner/banner.jpg"),
    title: "Activator",
    shortTitle: "Activator",
    id: "activator",
    hidden: false,
    logo: require("../../assets/images/ourWork/our-work-icons/our-clients/activator-logo.png"),
    shortDescription:
      "Activator is a dynamic platform designed to streamline and optimize workflow processes for businesses of all sizes.",
    cardDescription:
      "Activator is a dynamic platform designed to streamline and optimize workflow processes for businesses of all sizes.",
    description: (
      <div>
        Activator is a dynamic platform designed to streamline and optimize
        workflow processes for businesses of all sizes. Activator offers a suite
        of features designed to streamline content production, simplify MLR
        (Medical, Legal, Regulatory) processes, and maximize value from Veeva
        Vault. Their platform accelerates content creation by providing tools
        for faster production, enabling teams to efficiently collaborate and
        iterate.
        <br />
        <br />
        Activator facilitates the review and approval of content, reducing
        bottlenecks and ensuring compliance with regulatory standards.
        Additionally, the platform enhances the utilization of Veeva Vault by
        extracting maximum value from the system, optimizing content management
        and workflow efficiency. Activator empowers organizations to expedite
        their content creation processes, improve regulatory compliance, and
        maximize the benefits of their Veeva Vault investment, ultimately
        driving productivity and innovation in the pharmaceutical and life
        sciences industries.
      </div>
    ),
    technologies: [
      "Vue",
      "Vuex",
      "Nuxt",
      "Auth0",
      "AWS",
      "Ably",
      "Webpack",
      "JavaScript",
      "ArangoDB",
      "Node.js"
    ],
    services: ["API Development", "Web Development"],
    servicesCategory: ["App Development"],
    servicesTags: ["App Development"],
    categories: ["Featured"],
    mainCategory: ["Web"],
    linkId: "activator",
    challenges: [
      {
        challenge: (
          <div>
            Ensuring seamless communication with more complex systems like Veeva
            Vault.
            <br /> <br />
            Managing the scalability of the platform to accommodate growing user
            numbers, larger data volumes, concurrent user interactions, and the
            addition of new features.
            <br /> <br />
            Providing support for legacy content.
          </div>
        ),
        solution: (
          <div>
            Activator removes friction from the whole content creation,
            approval, and localisation process, which means more content,
            created faster, and without compromise.
            <br /> <br />
            Simplify and speed MLR reviews. Activator enables content history to
            be easily traced, makes approval status clearly visible, removes the
            unnecessary duplication of work, and helps MLR colleagues to focus
            their attention just where it’s needed.
            <br /> <br />
            Speed content localisation throughout your entire organization.
            Activator makes it easy to track content adaptation and translation
            across markets and provides localisation tools that simplify and
            clarify the process for affiliates.
            <br /> <br />
            Activator keeps the content safe in Veeva Vault while making
            everything easier to overview, search, adapt, re-use and share,
            getting the full value from all creative investments.
          </div>
        )
      }
    ]
  }
];

export default activatorData;
