import React from 'react';

const squeezerData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/squeezer/small/0.jpg'),
        large: require('../../assets/images/ourWork/squeezer/large/0.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/squeezer/small/1.jpg'),
        large: require('../../assets/images/ourWork/squeezer/large/1.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/squeezer/small/2.jpg'),
        large: require('../../assets/images/ourWork/squeezer/large/2.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/squeezer/card/squeezer.jpg'),
    bannerImage: require('../../assets/images/ourWork/squeezer/banner/banner.jpg'),
    title: 'Squeezer Platform',
    shortTitle: 'Squeezer Platform',
    id: 'squeezer',
    shortDescription: `Squeezer is an open source CLI framework for building and deploying decentralized applications. 
    It was designed to help developers with better architectures on serverless zero-administration compute platforms
     where code runs on the top of microservices clouds such as AWS Lambda, Azure Functions, IBM OpenWhisk & 
     Google Functions.`,
    cardDescription: 'An open source CLI framework for building and deploying decentralized applications',
    description:
  <div>
    <a href="https://squeezer.io/" target="_blank" rel="noreferrer">Squeezer </a>
    is an open source CLI framework for building and deploying decentralized applications.
    <br />
    <br />
    It was designed to help developers with better architectures on serverless zero-administration compute platforms
    where code runs on the top of microservices clouds such as AWS Lambda,
    Azure Functions, IBM OpenWhisk & Google Functions.
  </div>,
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-partners/squeezer-min.png'),
    services: ['Product Strategy', 'UI/UX Design', 'Project Management', 'API Development', 'Web Development',
      'Devops & Infrastructure', 'QA & Automation'],
    technologies: ['React', 'React-Router', 'Redux', 'Redux-Thunk', 'Ant Design', 'Squeezer client API', 'CSS', 'LESS',
      'squeezer-cli'],
    categories: ['Open Source'],
    mainCategory: ['Web'],
    servicesCategory: ['Cloud Infrastructure'],
    servicesTags: ['Cloud Infrastructure', 'UX/UI Design'],
    linkId: 'squeezer',
    challenges: [{
      challenge:
  <div>
    Our main challenge was to build an intuitive, highly scalable dashboard that is able to accomodate all the
    deployment information–in one place and in real time.
    <br />
    <br />
    Ultimately, our goal was to build a simple and effortless platform that would speed up and improve developers’
    workflow.
  </div>,
      solution:
  <div>
    The platform was built on React, Redux and socket.io. How? By saving the output received from the socket message on
    the redux state for deployments that are pending and grabbing the output
    from the Deployment entity for finished deploys.
    <br />
    <br />
    We implemented powerful features to improve deployment workflow and speed:
    <ul type="disc">
      <li>
        <strong>Intuitive deployment - </strong>
        The platform ensures a continuous deployment & continuous integration strategy,
        thus keeping all services up to date.
      </li>
      <li>
        <strong> Multi provider - </strong>
        Users are able to select the provider and the region where they want to deploy their decentralized application.
      </li>
      <li>
        <strong>Smart testing - </strong>
        Users are able to run tests against new or modified services.
      </li>
    </ul>
  </div>,
    }]
  }
];

export default squeezerData;
