import React from "react";

const apNewsData = [
  {
    images: [
      {
        small: require("../../assets/images/ourWork/ap-news/small/1.jpg"),
        large: require("../../assets/images/ourWork/ap-news/large/1.jpg")
      },
      {
        small: require("../../assets/images/ourWork/ap-news/small/2.jpg"),
        large: require("../../assets/images/ourWork/ap-news/large/2.jpg")
      },
      {
        small: require("../../assets/images/ourWork/ap-news/small/3.jpg"),
        large: require("../../assets/images/ourWork/ap-news/large/3.jpg")
      },
      {
        small: require("../../assets/images/ourWork/ap-news/small/4.jpg"),
        large: require("../../assets/images/ourWork/ap-news/large/4.jpg")
      }
    ],
    cardImage: require("../../assets/images/ourWork/ap-news/card/ap-news-thumbnail.jpg"),
    bannerImage: require("../../assets/images/ourWork/ap-news/banner/banner.jpg"),
    title: "AP News",
    shortTitle: "AP News",
    id: "ap-news",
    hidden: false,
    logo: require("../../assets/images/ourWork/our-work-icons/our-clients/ap-logo.png"),
    shortDescription:
      "AP News Mobile App is a news mobile platform focused on offering a native mobile experience and functionalities that allow the users to interact with the news articles",
    cardDescription:
      "AP News Mobile App is a news mobile platform focused on offering a native mobile experience and functionalities that allow the users to interact with the news articles",
    description: (
      <div>
        AP News Mobile App is a news mobile platform focused on offering a
        native mobile experience and functionalities that allow the users to
        interact with the news articles. It’s been implemented fully with native
        code Swift for iOS and Kotlin for Android.
        <br />
        <a href={"https://apps.apple.com/ro/app/ap-news/id364677107"}>
          App Store
        </a>{" "}
        <br />
        <a href={"https://play.google.com/store/apps/details?id=mnn.Android"}>
          Play Store
        </a>
      </div>
    ),
    technologies: [
      "Swift",
      "SwiftUI",
      "Kotlin",
      "Jetpack",
      "Compose",
      "JWPlayer SDK",
      "GraphQL"
    ],
    services: [
      "Architecture",
      "Software Development",
      "Mobile Development",
      "UI/UX Design"
    ],
    servicesCategory: ["App Development"],
    servicesTags: ["App Development"],
    categories: ["Featured", "Events"],
    mainCategory: ["Mobile"],
    linkId: "ap-news",
    challenges: [
      {
        challenge: (
          <div>
            1. Integrate web components such as HTML embeds, video players and
            customize them in order to provide the best UX.
            <br /> <br />
            2. Memory management mechanism for image loading.
            <br /> <br />
            3. SDK integration for advertising inside the app.
            <br /> <br />
            4. Create different UI for mobile devices, Ipad and watches.
            <br /> <br />
            5. Video Player integration with picture-in-picture and video
            docking using JWPlayer, in app and OS wise.
          </div>
        ),
        solution: (
          <div>
            1. We’ve implemented a customisable news platform app using native
            code
            <br /> <br />
            2. The app offers the user the possibility to interact with the
            news, filter them by category, bookmark any article for later review
            or search a specific article. The users can customize the preferred
            categories and articles
            <br /> <br />
            3. We redesigned the article page by creating a native iOS & Android
            component for each type of content available such as video
            component, audio component, iframes, html embedded.
            <br /> <br />
            4. For the shorter videos we implemented a vertical video playlist
            /stories style which allows the user to see the content by swiping
            up and down (tiktok style).
          </div>
        )
      }
    ]
  }
];

export default apNewsData;
