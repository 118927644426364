import React from "react";

const amplifyData = [
  {
    images: [
      {
        small: require("../../assets/images/ourWork/amplify/small/1.jpg"),
        large: require("../../assets/images/ourWork/amplify/large/1.jpg")
      },
      {
        small: require("../../assets/images/ourWork/amplify/small/2.jpg"),
        large: require("../../assets/images/ourWork/amplify/large/2.jpg")
      },
      {
        small: require("../../assets/images/ourWork/amplify/small/3.jpg"),
        large: require("../../assets/images/ourWork/amplify/large/3.jpg")
      },
      {
        small: require("../../assets/images/ourWork/amplify/small/4.jpg"),
        large: require("../../assets/images/ourWork/amplify/large/4.jpg")
      }
    ],
    cardImage: require("../../assets/images/ourWork/amplify/card/amplify-thumbnail.jpg"),
    bannerImage: require("../../assets/images/ourWork/amplify/banner/banner.jpg"),
    title: "Amplify",
    shortTitle: "Amplify",
    id: "amplify",
    hidden: false,
    logo: require("../../assets/images/ourWork/our-work-icons/our-clients/amplify-logo.png"),
    shortDescription:
      "Amplify by Anthill is a platform designed to enhance healthcare professionals satisfaction through self-detailing",
    cardDescription:
      "Amplify by Anthill is a platform designed to enhance healthcare professionals satisfaction through self-detailing",
    description: (
      <div>
        Amplify by Anthill is a platform designed to enhance healthcare
        professionals satisfaction through self-detailing. It provides
        on-demand, interactive content that allows healthcare professionals to
        access relevant information whenever needed.
        <br />
        <br />
        The platform features a no-code builder for creating self-detailing
        experiences, facilitating personalized content delivery and direct
        meeting bookings. It aims to boost engagement, reduce bounce rates, and
        support pharma companies in expanding their marketing reach and return
        on investment. Amplify integrates seamlessly with various systems,
        maintaining brand consistency and supporting limitless integrations.
      </div>
    ),
    technologies: [
      "Vue",
      "Vuex",
      "Nuxt",
      "Auth0",
      "AWS",
      "Ably",
      "Webpack",
      "JavaScript",
      "TypeScript",
      "AntV X6",
      "DynamoDB"
    ],
    services: ["API Development", "Web Development"],
    servicesCategory: ["App Development"],
    servicesTags: ["App Development"],
    categories: ["Featured", "Healthcare"],
    mainCategory: ["Web"],
    linkId: "amplify",
    challenges: [
      {
        challenge: (
          <div>
            Developing from scratch a no-code builder that allows for highly
            personalized content that can be embedded within existing web
            platforms or hosted as a standalone solution.
            <br /> <br />
            Ensuring seamless integration with existing systems and
            technologies, which involves complex API and data management.
          </div>
        ),
        solution: (
          <div>
            Amplify was developed as a web app platform that includes a
            comprehensive builder that enables users to design interactive
            chatbot interfaces utilizing pre-existing content or creating new
            ones. The chatbot can be easily integrated in current web platforms
            and can also function independently as a standalone web application,
            offering flexibility and versatility in deployment.
            <br /> <br />
            We integrated the existing eDetailing system (also known as
            electronic or remote detailing) with our solution ensuring
            compatibility and data synchronization between the disparate systems
            <br /> <br />
            We established connectivity capabilities with various Customer
            Relationship Management (CRM) ensuring the information collected
            through Amplify’s self-detailing and interactive content can be
            easily managed and utilized within existing CRM platforms.
          </div>
        )
      }
    ]
  }
];

export default amplifyData;
