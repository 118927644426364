import React from 'react';

const conferenceCompassData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/conference-compass/small/1.jpg'),
        large: require('../../assets/images/ourWork/conference-compass/large/1.jpg')
      },
      {
        small: require('../../assets/images/ourWork/conference-compass/small/2.jpg'),
        large: require('../../assets/images/ourWork/conference-compass/large/2.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/conference-compass/small/3.jpg'),
        large: require('../../assets/images/ourWork/conference-compass/large/3.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/conference-compass/small/4.jpg'),
        large: require('../../assets/images/ourWork/conference-compass/large/4.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/conference-compass/small/5.jpg'),
        large: require('../../assets/images/ourWork/conference-compass/large/5.jpg'),
      },
      {
        small: require('../../assets/images/ourWork/conference-compass/small/6.jpg'),
        large: require('../../assets/images/ourWork/conference-compass/large/6.jpg'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/conference-compass/card/compass.jpg'),
    bannerImage: require('../../assets/images/ourWork/conference-compass/banner/banner.jpg'),
    title: 'Conference Compass | Virtual Events Platform',
    shortTitle: 'Conference Compass',
    id: 'Conference-Compass',
    hidden: false,
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/conference-compass.png'),
    shortDescription: 'A service which offers powerful, highly customizable software solutions for virtual,'
      + ' hybrid or live events.',
    cardDescription: 'Custom software solutions for virtual, hybrid or live events',
    description:
  <div>
    <a href="https://www.conferencecompass.com/" target="_blank" rel="noreferrer">Conference Compass</a>
    {' '}
    is a pioneer in developing interactive applications for conferences and events. Founded in 2010, it is the trusted
    supplier of the world's largest associations, PCO's and meeting planners all over the world.
    {' '}
    <br />
    {' '}
    <br />
    Compass Conference has partnered with MCRO to reimagine the way events are delivered and handled,
    be they live or virtual.
    <br />
    {' '}
    <br />
    Together we set out to find a seamless solution that would scale and adapt to an ever-changing world.
    We helped them shift from live events to virtual and hybrid ones, constantly adapting and responding
    to the needs of the community.
    <br />
    {' '}
    <br />
    Integrated video streams, recordings, and interactive networking tools are just a few of the many features
    we integrated to make virtual events come to life.
  </div>,
    technologies: ['JavaScript', 'React', 'React Native', 'API', 'REST', 'Cross Platform', 'Ruby',
      'FastLine', 'CircleCI', 'Shell script', 'Automated Delivery', 'OneSignal'],
    services: ['Software Development', 'Project Strategy', 'QA & Automation', 'DevOps & Infrastructure',
      'Business Analysis'],
    results: [
      { percentage: '50%+', text: 'Revenue Growth' },
      { percentage: '20%', text: 'Cost Reduction' },
      { percentage: '40%', text: 'Customer Acquisiton' },
      { percentage: '70%', text: 'Lead Velocity Increase' }],
    testimonial: {
      imageSrc: require('../../assets/images/homePage/bjarni.jpg'),
      quote: 'Working with MCRO has been a pleasure since the very start. It was easy to trust part of the development '
        + 'of our product to their team, after discovering that we share many of the same core values and best '
        + 'practices. We consider their team members working on our project to be an integral part of our own team '
        + 'and are excited with the prospect to continue the collaboration.',
      author: 'Bjarni Gunnarsson',
      position: 'CTO at Conference Compass' },
    servicesCategory: ['App Development'],
    servicesTags: ['App Development'],
    categories: ['Featured', 'Events'],
    mainCategory: ['Mobile'],
    linkId: 'conference-compass',
    challenges: [{
      challenge:
  <div>
    1. Develop fully customizable mobile solutions that support continuous delivery and integration between
    different platforms.
    <br />
    {' '}
    <br />
    2. Make a prompt transition from live events to virtual and hybrid ones in response to 2020 events; we
    moved fast to respond to the ever-changing needs of the industry and to implement features that would
    allow the community to reconnect virtually.
  </div>,
      solution:
  <div>
    1. We've implemented a highly customizable white label app using React Native. We pushed the limits
    of Fastlane, CircleCI, and shell scripts so the process of building and deploying the app is automated,
    fast, and with minimal cost of maintenance.
    <br />
    {' '}
    <br />
    2. We helped develop a seamless solution to migrate to virtual by expanding the mobile platform
    into a Virtual Event Platform:
    <br />
    <ul type="disc">
      <li>Integrated live streaming and recordings</li>
      <li>Implemented interaction features such as session chatting, live polling, and QA sessions</li>
      <li>Implemented evaluation forms and real-time analytics</li>
      <li>
        Developed a user-friendly web app for event programs, making it possible for users to access
        the event app from any device
      </li>
    </ul>
  </div>,
    }]
  },
];

export default conferenceCompassData;
