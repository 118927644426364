import React from 'react';

const outcomes4meData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/outcomes4me/small/0.png'),
        large: require('../../assets/images/ourWork/outcomes4me/large/0.png'),
      },
      {
        small: require('../../assets/images/ourWork/outcomes4me/small/1.png'),
        large: require('../../assets/images/ourWork/outcomes4me/large/1.png'),
      },
      {
        small: require('../../assets/images/ourWork/outcomes4me/small/2.png'),
        large: require('../../assets/images/ourWork/outcomes4me/large/2.png'),
      },
      {
        small: require('../../assets/images/ourWork/outcomes4me/small/3.png'),
        large: require('../../assets/images/ourWork/outcomes4me/large/3.png'),
      },
      {
        small: require('../../assets/images/ourWork/outcomes4me/small/4.png'),
        large: require('../../assets/images/ourWork/outcomes4me/large/4.png'),
      },
      {
        small: require('../../assets/images/ourWork/outcomes4me/small/5.png'),
        large: require('../../assets/images/ourWork/outcomes4me/large/5.png'),
      },
      {
        small: require('../../assets/images/ourWork/outcomes4me/small/6.png'),
        large: require('../../assets/images/ourWork/outcomes4me/large/6.png'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/outcomes4me/card/outcomes4me.jpg'),
    bannerImage: require('../../assets/images/ourWork/outcomes4me/banner/banner.jpg'),
    title: 'Outcomes4me | AI Based Healthcare Platform',
    shortTitle: 'Outcomes4me | AI Based Healthcare Platform',
    id: 'Outcomes_for_Me',
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/outcomes4me-min.png'),
    shortDescription: 'An AI medical platform dedicated to helping cancer patients navigate all aspects '
      + 'of their treatment by providing them with personalized tools and resources.',
    cardDescription: 'A medical app dedicated to helping cancer patients manage and track their treatments',
    description:
  <div>
    <a href="http://www.outcomes4me.com/">Outcomes4me</a>
    {' '}
    is an AI based medical mobile app and platform built to help cancer patients navigate their treatment and care.
    <br />
    <br />
    The app helps patients achieve better outcomes by analyzing their health information and providing them with
    personalized options for approved treatments and clinical trials. Moreover,
    users are provided with tools to track their treatments, symptoms and outcomes.
    <br />
    <br />
    MCRO has developed this application in partnership with MVP Factory;
    our main focus was the react-native mobile app and automated builds configuration.
  </div>,
    services: ['Mobile Development', 'QA & Automation'],
    technologies: ['React Native', 'ES@Next (6+)', 'Redux', 'React Navigation', 'Canvas', 'SVG', 'iOS', 'Android',
      'Cross Platform', 'Expo.IO', 'NodeJS', 'GraphQL', 'Socket.IO'],
    results: [
      { percentage: '10K+', text: 'Patients' },
      { percentage: '1K+', text: 'Doctors' },
      { percentage: '100K+', text: 'Successful Diagnostics' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Healthcare'],
    mainCategory: ['Mobile'],
    servicesCategory: [],
    servicesTags: [],
    linkId: 'outcomes4me',
    challenges: [{
      challenge:
  <div>
    Users have to go through a medical questionnaire to complete the registration,
    but also a second time during the onboarding process, after they sign in for the first time.
    <br />
    <br />
    Our biggest challenge was to develop a dynamic questionnaire where question_2
    is dependent on question_1’s answer.
    <br />
    <br />
    The problem with this was that every time an user updated an answer,
    we had to reset the questionnaire (and make the request for the list of results)
    from that point on; if the user decided to go back, we had to reset the questionnaire
    back with the previous answers (and again, making the request for the list of results).
  </div>,
      solution:
  <div>
    The solution was to create the correct redux structure so that the saved answers
    were kept separately, the latest answers were used for the API requests and
    all the properties were set properly depending on the user's action: submit,
    cancel or go back to previous question.
    <br />
    <br />
    As in all of our projects, we worked in an agile and flexible way.
    The daily standups, weekly status reports and demo meetings helped our team
    stay in sync and up-to-date in all areas of the project.
    <br />
    <br />
    The continuous collaboration we maintained with various stakeholders from our client’s
    part helped us ensure that all parties were on the same page and working towards the same common goal.
  </div>
    }]
  },
];

export default outcomes4meData;
