import React from 'react';

const mariChefData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/mari-chef/small/0.png'),
        large: require('../../assets/images/ourWork/mari-chef/large/0.png'),
      },
      {
        small: require('../../assets/images/ourWork/mari-chef/small/1.png'),
        large: require('../../assets/images/ourWork/mari-chef/large/1.png'),
      },
      {
        small: require('../../assets/images/ourWork/mari-chef/small/2.png'),
        large: require('../../assets/images/ourWork/mari-chef/large/2.png'),
      },
      {
        small: require('../../assets/images/ourWork/mari-chef/small/3.png'),
        large: require('../../assets/images/ourWork/mari-chef/large/3.png'),
      },
      {
        small: require('../../assets/images/ourWork/mari-chef/small/4.png'),
        large: require('../../assets/images/ourWork/mari-chef/large/4.png'),
      },
      {
        small: require('../../assets/images/ourWork/mari-chef/small/5.png'),
        large: require('../../assets/images/ourWork/mari-chef/large/5.png'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/mari-chef/card/mari-chef.jpg'),
    bannerImage: require('../../assets/images/ourWork/mari-chef/banner/banner.jpg'),
    title: 'MARi | AI Educational Platform',
    shortTitle: 'MARi | AI Educational Platform',
    id: 'MARI_CHEF',
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/mari-min.png'),
    /* eslint-disable */
    shortDescription: 'An AI-based personal learning platform whose mission is to optimize the way we acquire knowledge'
      + ' and skills—think of it as a “personal learning GPS”.',
    cardDescription: 'An AI-based learning platform to optimize knowledge and skill development',
    description:
  <div>
    <a href='https://www.mari.com' target='_blank' rel="noreferrer">MARi</a>
    {' '}
    's <strong>'Chef' </strong>
    platform is an AI for Academic & Workplace Skills. Described as a “personal learning GPS”,
    its main purpose is to help universities and organizations provide data-based guidance to their students
    or employees in terms of skill progression and career path.
    <br/><br/>
    The <strong>'Chef' </strong> platform has the ability to create a skill-gap analysis to any defined academic standard,
    certification, occupation, or job,
    and then recommending the best path to the destination while tracking a person’s progress all along the way.
    <br/><br/>
    Another important part of MARi is the ‘Reports’ platform, a complex report management platform that uses the power of AI
    to gather and correlate immense pools of data from different educational sources.
    <br/><br/>
    The ‘Reports’ platform made the life of school personnel (teachers and administrative staff)
    considerably easier by gathering educational data processed by a powerful AI,
    aggregating and presenting it in an extremely accessible manner.
    <br/><br/>
    MCRO is proud to be a part of this project and to help MARi with front-end development,
    back-end development, and DevOps.
  </div>,
    /* eslint-enable */
    services: ['Product Strategy', 'Software Development'],
    technologies: ['React', 'React-Redux', 'React-Router 4+', 'Redux-Saga', 'Typescript + ES@Next (6+)', 'Webpack 2+',
      'Styled Components', 'Enzyme', 'Jest', 'Python', 'AWS', 'Firebase', 'Firestore', 'Elastic Search', 'Kubernetes',
      'Circle CI'],
    results: [
      { percentage: '20+', text: 'Universities' },
      { percentage: '20K', text: 'Onboarded Students' },
      { percentage: '10x', text: 'Revenue Growth' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Education', 'Financial'],
    mainCategory: ['Web'],
    servicesCategory: ['Web Development'],
    servicesTags: ['Web Development'],
    linkId: 'mari-chef',
    challenges: [
      {
        challenge:
  <div>
    MARi’s success is based on being able to process hundreds of thousands of data and to process it so
    as to result in individual skill maps and career paths for any given person.
    <br />
    <br />
    Our main challenge was to develop an infinitely nested data chart with extremely complex contents on the UI.
  </div>,
        solution:
  <div>
    Implement a recursively looped component chain in React coupled together with ElasticSearch for
    a snappy and seamless user experience without any loss in performance, even on mobile.
    <br />
    <br />
    Our team managed to stay in sync and up-to-date throughout the project by working in an Agile environment.
    The daily standups and weekly status reports helped keep us on the right track.
    <br />
    <br />
    At the end of the project, we were proud to have been able to meet—and even exceed—our client’s requirements.
  </div>
      }
    ]
  },
];

export default mariChefData;
