import React from 'react';

const kaitoRfpData = [
  {
    images: [
      {
        small: require('../../assets/images/ourWork/kaito-rfp/small/1.png'),
        large: require('../../assets/images/ourWork/kaito-rfp/large/1.png'),
      },
      {
        small: require('../../assets/images/ourWork/kaito-rfp/small/2.png'),
        large: require('../../assets/images/ourWork/kaito-rfp/large/2.png'),
      },
      {
        small: require('../../assets/images/ourWork/kaito-rfp/small/3.png'),
        large: require('../../assets/images/ourWork/kaito-rfp/large/3.png'),
      },
      {
        small: require('../../assets/images/ourWork/kaito-rfp/small/4.png'),
        large: require('../../assets/images/ourWork/kaito-rfp/large/4.png'),
      },
      {
        small: require('../../assets/images/ourWork/kaito-rfp/small/5.png'),
        large: require('../../assets/images/ourWork/kaito-rfp/large/5.png'),
      },
      {
        small: require('../../assets/images/ourWork/kaito-rfp/small/6.png'),
        large: require('../../assets/images/ourWork/kaito-rfp/large/6.png'),
      },
      {
        small: require('../../assets/images/ourWork/kaito-rfp/small/7.png'),
        large: require('../../assets/images/ourWork/kaito-rfp/large/7.png'),
      },
      {
        small: require('../../assets/images/ourWork/kaito-rfp/small/8.png'),
        large: require('../../assets/images/ourWork/kaito-rfp/large/8.png'),
      },
    ],
    cardImage: require('../../assets/images/ourWork/kaito-rfp/card/kaito-rfp.jpg'),
    bannerImage: require('../../assets/images/ourWork/kaito-rfp/banner/banner.jpg'),
    title: 'Kaito RFP | Request for Proposal and Gov Auctions',
    shortTitle: 'Kaito RFP | Request for Proposal and Gov Auctions',
    id: 'Kaito',
    logo: require('../../assets/images/ourWork/our-work-icons/our-clients/kaito-logo.png'),
    shortDescription: 'A RFP response automation platform that aims to help sales teams deliver faster and '
      + 'higher quality RFP responses. ',
    cardDescription: 'An RFP response automation platform that helps sales teams respond faster and better',
    description:
  <div>
    Quandora’s
    {' '}
    <a href="http://kaito-rfp.com/" target="_blank" rel="noreferrer">
      Kaito
      RFP
    </a>
    {' '}
    is a response automation platform that aims to help sales teams deliver faster and higher quality RFP responses.
    <br />
    <br />
    MCRO is proud to have designed and developed the React Web Application,
    as well as the product presentation website.
    We helped streamline the RFP process with the help of our automation solutions:
    <br />
    <ul type="disc">
      <li>Implemented an intelligent dynamic database able to match RFP requirements to response content;</li>
      <li>
        Implemented the ability to upload RFP documents, collaborate on answers,
        export final responses in the original documents or suggested templates;
      </li>
      <li>Implemented an AI-powered relationship engine able to recognize similar questions and suggest responses;</li>
      <li>Implemented a self-learning system, constantly increasing the response automation ratio.</li>
    </ul>
  </div>,
    services: ['Product Strategy', 'UI/UX Design', 'Software Development', 'Business Analysis', 'QA & Automation'],
    technologies: ['React', 'ES@Next (6+)', 'Redux-Thunk', 'Webpack 3+', 'React-Router 4+', 'Redux', 'Redux-Saga',
      'SASS', 'AWS', 'SSO Auth', 'Wordpress CMS', 'Design', 'UX', 'UI'],
    results: [
      { percentage: '100+', text: 'Customers' },
      { percentage: '5+', text: 'Government Agencies' },
      { percentage: '50K', text: 'RFPs Created' },
      { percentage: '+1', text: 'Happy Client' }],
    categories: ['Financial'],
    mainCategory: ['Web'],
    servicesCategory: [],
    servicesTags: [],
    linkId: 'kaito-rfp',
    challenges: [{
      challenge:
  <div>
    Efficiently responding to numerous similar, but non-standardized RFP's is a big challenge for sales teams.
    {' '}
    <a href="https://mcro.tech/our-work/quandora/" target="_blank" rel="noreferrer">
      Quandora
    </a>
    {' '}
    partnered with MCRO to reimagine the way RFPs are handled.
    <br />
    <br />
    Our main goal was to build a fast, scalable and modern RFP client from the ground up, in a record time.
    <br />
    <br />
    When it comes to functionality, the Web System was required to handle role-based security,
    allowing authorized users to manipulate RFPs.
    MCRO needed to ensure that the Kaito team would be able to maintain and implement new features going forward.
  </div>,
      solution:
  <div>
    In terms of performance, ReactJS is extremely fast due to its virtual DOM. Given our ReactJS expertise,
    we can guarantee extremely fast development, which is why we decided to go with this solution.
    <br />
    <br />
    When it comes to architecture, we decided to use Redux because of the platform’s complex functionality;
    this way we ensured a consistent management of the application’s state together with scalability.
    <br />
    <br />
    During the development process,
    MCRO worked closely with the Kaito software developers so as to ensure the project continuity and sustainability.
  </div>
    }]
  },
];

export default kaitoRfpData;
